import React, { useState } from "react";
import styles from "./styles.module.css";
import { FaPlus, FaTimes } from "react-icons/fa";

const Accordion = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.accordion}>
      {data.map((item, index) => (
        <div key={index} className={styles.accordionItem}>
          <div
            className={styles.accordionTitle}
            onClick={() => handleToggle(index)}
          >
            <div className={styles.titleDiv}>
              <span>{item.numbering}</span>{" "}
              <span className={styles.title}>{item.title}</span>
            </div>
            <div className={styles.icon}>
              {activeIndex === index ? (
                <FaTimes color="#1D1D1D" size={20} />
              ) : (
                <FaPlus color="#1D1D1D" size={20} />
              )}
            </div>
          </div>
          {activeIndex === index && (
            <div className={styles.accordionBody}>
              <p>{item.body}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
