import React from "react";
import Landing from "./pages/Landing/landing";
import ProgramSearch from "./pages/programSearch/programSearch";

import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import Dashboard from "./pages/dashboard/dashboard";
// students
import Students from "./pages/students/students"
import StudentDetails from "./pages/students/studentDetails";
import AddStudent from "./pages/students/addStudent";
// programs
import Programs from "./pages/programs/programs";
import AddProgram from "./pages/programs/addProgram";
import ProgramDetails from "./pages/programs/programDetails";
// universities
import Universities from "./pages/universities/universities";
import AddUniversity from "./pages/universities/addUniversity";
import UniversityDetails from "./pages/universities/universityDetails";
// admins
import Admins from "./pages/admins/admins";
import AddAdmin from "./pages/admins/addAdmin";
import AdminDetails from "./pages/admins/adminDetails";
// emails
import Emails from "./pages/emails/emails";
// profile
import Profile from "./pages/profile/profile";
// applicatons
import Applications from "./pages/applications/applications";
import ApplicationDetails from "./pages/applications/applicationDetails";

import PageError from "./pages/PageError";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* UNPROTECTED ROUTES */}
          <Route path="/" element={<Landing />} />
          <Route path="/search-program" element={<ProgramSearch />} />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/dashboard" element={<Dashboard />} />

          {/* STUDENTS */}
          <Route path="/students" element={<Students />} />
          <Route path="/students-details" element={<StudentDetails />} />
          <Route path="/add-student" element={<AddStudent />} />

          {/* PROGRAMS */}
          <Route path="/programs" element={<Programs />} />
          <Route path="/program-details" element={<ProgramDetails />} />
          <Route path="/add-program" element={<AddProgram />} />

          {/* UNIVERSITIES */}
          <Route path="/universities" element={<Universities />} />
          <Route path="/university-details" element={<UniversityDetails />} />
          <Route path="/add-university" element={<AddUniversity />} />

          {/* ADMINS */}
          <Route path="/admins" element={<Admins />} />
          <Route path="/admin-details" element={<AdminDetails />} />
          <Route path="/add-admin" element={<AddAdmin />} />

          {/* APPLICATIONS */}
          <Route path="/applications" element={<Applications />} />
          <Route path="/application-details" element={<ApplicationDetails />} />

          {/* EMAILS */}
          <Route path="/emails" element={<Emails />} />

          {/* PROFILE */}
          <Route path="/profile" element={<Profile />} />

          {/* 404 ROUTE */}
          <Route path="*" element={<PageError />} />
        </Routes>
      </Router>
      {/* USING THE TOASTIFY CONTAINER */}
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
    </div>
  );
}

export default App;
