import React from "react";

const PageError = () => {
  return (
    <div className="vh-100">
      <div
        className="authincation h-100"
        style={{
          backgroundImage: "url(images/student-bg.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-lg-6 col-sm-12">
              <div className="form-input-content  error-page">
                <h1 className="error-text text-primary">404</h1>
                <h4> The page you were looking for is not found!</h4>
                <p>
                  You may have mistyped the address or the page may have moved.
                </p>
                <a className="btn btn-primary" href="/">
                  Back to Home
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <img className="w-100" src="images/svg/student.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageError;
