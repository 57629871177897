// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_language__1f6KU select {
    border: none;
    padding: 5px 0;
    font-weight: bold;
    width: 40px !important;
}`, "",{"version":3,"sources":["webpack://./src/i18next/changeLanguage/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,cAAc;IACd,iBAAiB;IACjB,sBAAsB;AAC1B","sourcesContent":[".language select {\n    border: none;\n    padding: 5px 0;\n    font-weight: bold;\n    width: 40px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"language": `styles_language__1f6KU`
};
export default ___CSS_LOADER_EXPORT___;
