import React, { useContext } from "react";
import About from "../about/About";
import University from "../university/University";
import Requirements from "../requirements/Requirements";
import Conditions from "../conditions/Conditions";
import Settings from "../settings/Settings";
import { AuthContext } from "../../../context/AuthContext";

const Tab = ({ program, university }) => {
  const { role } = useContext(AuthContext);
  const tabList = [
    {
      title: "More Info",
      href: "about",
    },
    {
      title: "University Info",
      href: "university",
    },
    {
      title: "Admission Requirements",
      href: "requirements",
    },
    {
      title: "Conditions for Acceptance",
      href: "conditions",
    },
    role !== "STUDENT" && {
      title: "Settings",
      href: "settings",
    },
  ].filter(Boolean);

  return (
    <div class="col-xl-12">
      <div class="card h-auto">
        <div class="card-body">
          <div class="profile-tab">
            <div class="custom-tab-1">
              <ul class="nav nav-tabs">
                {tabList.map((item, index) => {
                  return (
                    <li class="nav-item" key={index}>
                      <a
                        href={`#${item.href}`}
                        data-bs-toggle="tab"
                        class={`nav-link ${index === 0 && "active show"}`}
                      >
                        {item.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
              <div class="tab-content">
                <About program={program} />
                <University university={university} />
                <Requirements program={program} />
                <Conditions program={program} />
                <Settings program={program} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tab;
