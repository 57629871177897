import React, { useState, useEffect, useContext } from "react";
import Layout from "../../layouts/Layout";
import Tab from "../../components/program/tab/Tab";
import Students from "../../components/dashboard/students/Students";
import Applications from "../../components/application/applications/Applications";

import Loader from "../../components/Loader/Loader";
import { useLocation } from "react-router-dom";
import { getProgram } from "../../redux/reducers/program";
import { getUniversity } from "../../redux/reducers/university";
import { getStudents } from "../../redux/reducers/user";
import { addApplication } from "../../redux/reducers/application";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import Modal from "../../components/modal/Modal";
import Select from "react-select";
import { ColorRing } from "react-loader-spinner";
import { AuthContext } from "../../context/AuthContext";

const ProgramDetails = () => {
  const { search } = useLocation();
  const { role, userId, username } = useContext(AuthContext);
  const id = search.split("?=")[1];

  const [program, setProgram] = useState(null);
  const [university, setUniversity] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleGetProgram = async (e) => {
    try {
      if (id) {
        setLoading(true);
        await dispatch(getProgram(id)).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setLoading(false);
            return;
          } else {
            setProgram(res.payload);
            setLoading(false);
            return;
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleGetUniversity = async (e) => {
    try {
      if (id) {
        setLoading(true);
        await dispatch(getUniversity(program.universityId)).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setLoading(false);
            return;
          } else {
            setUniversity(res.payload);
            setLoading(false);
            return;
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleGetProgram();
  }, [id]);

  useEffect(() => {
    handleGetUniversity();
  }, [program]);

  // MODAL VARIABLES
  const [students, setStudents] = useState([]);
  const [studentLoading, setStudentLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

  const handleGetStudents = async (e) => {
    try {
      setStudentLoading(true);
      await dispatch(getStudents()).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          setStudentLoading(false);
          return;
        } else {
          const structured = res.payload.map((item) => {
            return { label: item.fullName, value: item };
          });
          setStudents(structured);
          setStudentLoading(false);
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleGetStudents();
  }, []);

  const handleChange = (selectedOption) => {
    setSelectedStudent(selectedOption.value);
  };

  const handleAddApplication = async (e) => {
    e.preventDefault();
    try {
      let data = {
        programId: id,
        universityId: university._id,
        programName: program.name,
        universityName: university.name,
        abbreviation: university.abbreviation,
      };

      if (role === "STUDENT") {
        data = {
          ...data,
          studentId: userId,
          studentName: username,
        };
      } else {
        if (!selectedStudent) {
          toast.error("Select a student");
          return;
        }
        data = {
          ...data,
          studentId: selectedStudent._id,
          studentName: selectedStudent.fullName,
        };
      }

      setSubmitLoading(true);
      const res = await dispatch(addApplication(data));

      if (res.meta.requestStatus === "rejected") {
        toast.error(res.payload);
      } else {
        toast.success(res.payload.message);
      }

      setSubmitLoading(false);
      window.location.reload();
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <Layout>
      <div className="row">
        <div className="col-xl-12">
          <div className="card h-auto">
            <div className="card-header p-0">
              <div className="user-bg w-100">
                <div className="user-svg">
                  <svg
                    width="264"
                    height="109"
                    viewBox="0 0 264 109"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.0107422"
                      y="0.6521"
                      width="263.592"
                      height="275.13"
                      rx="20"
                      fill="#FCC43E"
                    />
                  </svg>
                </div>
                <div className="user-svg-1">
                  <svg
                    width="264"
                    height="59"
                    viewBox="0 0 264 59"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      y="0.564056"
                      width="263.592"
                      height="275.13"
                      rx="20"
                      fill="#FB7D5B"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row mt-2">
                <div className="d-flex justify-content-between mb-2">
                  <div></div>
                  {role === "STUDENT" ? (
                    <button
                      className="btn btn-primary"
                      onClick={handleAddApplication}
                      disabled={submitLoading}
                    >
                      + Apply Now
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#listStudent"
                    >
                      {submitLoading ? (
                        <ColorRing
                          visible={true}
                          height="22"
                          width="22"
                          ariaLabel="color-ring-loading"
                          wrapperStyle={{}}
                          wrapperClass="color-ring-wrapper"
                          colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                        />
                      ) : (
                        "+ Apply Now"
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          {loading || !program ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </div>
          ) : (
            <>
              {/* Tab */}
              <Tab program={program} university={university} />
            </>
          )}

          {/* Students */}
          {/* <Students /> */}

          {/* Applications */}
          {role !== "STUDENT" && <Applications filter="PROGRAM" id={id} />}

          {/* Modal */}
          <Modal
            id="listStudent"
            title="Students List"
            btnFunction={handleAddApplication}
            loading={submitLoading}
          >
            <div class="row">
              {studentLoading ? (
                <ColorRing
                  visible={true}
                  height="44"
                  width="44"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={["#000", "#000", "#000", "#000", "#000"]}
                />
              ) : (
                <div class="col-xl-12">
                  <div class="mb-3">
                    <label class="form-label mb-2">Students</label>
                    <Select
                      options={students}
                      onChange={handleChange}
                      isSearchable
                      placeholder="Select a student..."
                    />
                  </div>
                </div>
              )}
            </div>
          </Modal>
        </div>
      </div>
    </Layout>
  );
};

export default ProgramDetails;
