// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Widget_wrapper__cFhm3 {
    background-color: white;
    width: 850px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 10px 10px;
}

.Widget_optionSection__HWume {
    padding: 10px;
}

.Widget_optionSection__HWume p {
    margin: 0;
    color: #000;
}

@media (max-width: 768px) {
    .Widget_wrapper__cFhm3 {
        display: block;
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/programSearch/widget/Widget.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,SAAS;IACT,WAAW;AACf;;AAEA;IACI;QACI,cAAc;QACd,WAAW;IACf;AACJ","sourcesContent":[".wrapper {\n    background-color: white;\n    width: 850px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 10px;\n    padding: 10px 10px;\n}\n\n.optionSection {\n    padding: 10px;\n}\n\n.optionSection p {\n    margin: 0;\n    color: #000;\n}\n\n@media (max-width: 768px) {\n    .wrapper {\n        display: block;\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Widget_wrapper__cFhm3`,
	"optionSection": `Widget_optionSection__HWume`
};
export default ___CSS_LOADER_EXPORT___;
