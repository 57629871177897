import React, { useState } from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import { addUser } from "../../redux/reducers/user";

const Signup = () => {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  // const [amsId, setAmsId] = useState("");
  const [OLevelId, setOLevelId] = useState("");
  const [dob, setDob] = useState("");
  const [highschool, setHighSchool] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [gpa, setGpa] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const studentRegistration = async (e) => {
    e.preventDefault();
    try {
      if (
        name &&
        email &&
        phone &&
        OLevelId &&
        dob &&
        highschool &&
        gender &&
        address &&
        gpa &&
        password
      ) {
        setLoading(true);
        const data = {
          role: "STUDENT",
          fullName: name,
          emailAddress: email,
          phoneNumber: phone,
          password,
          // student details
          studentDetails: {
            ordinaryLevelIdentificationNumber: OLevelId,
            dateOfBirth: dob,
            gender: gender,
            address: address,
            highSchoolName: highschool,
            gradesGPA: gpa,
          },
        };
        await dispatch(addUser(data)).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setLoading(false);
            return;
          } else {
            toast.success(res.payload.message);
            window.location.href = "/login";
            setLoading(false);
            return;
          }
        });
      } else {
        toast.error("All fields are required");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="login-aside text-center  d-flex flex-column flex-row-auto">
        <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <div className="text-center mb-lg-4 mb-2 pt-5 logo">
            <img src="/logos/logo_white.png" alt="" style={{ width: "50%" }} />
          </div>
          <h3 className="mb-2 text-white">Welcome To Campus Camer!</h3>
          <p className="mb-4">
            Discover A Wide Range Of Study <br /> Programs Available In
            Cameroon.
          </p>
        </div>
        <div
          className="aside-image position-relative"
          style={{ backgroundImage: "url(images/background/pic-2.png)" }}
        >
          {/* <img
            className="img1 move-1"
            src="images/background/pic3.png"
            alt=""
          />
          <img
            className="img2 move-2"
            src="images/background/pic4.png"
            alt=""
          />
          <img
            className="img3 move-3"
            src="images/background/pic5.png"
            alt=""
          /> */}
        </div>
      </div>
      <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <div className="authincation-content style-2">
            <div className="row no-gutters">
              <div className="col-xl-12 tab-content">
                <div
                  id="sign-up"
                  className="auth-form tab-pane fade show active  form-validation"
                >
                  <form action="">
                    <div className="text-center mb-4">
                      <h3 className="text-center mb-2 text-black">
                        Create An Account
                      </h3>
                      <span>Welcome to Campus Camer</span>
                    </div>

                    {/* <div className="sepertor">
                      <span className="d-block mb-4 fs-13">Or with email</span>
                    </div> */}
                    <div className="col-xl-12">
                      <div className="row">
                        <div className="col-xl-6 col-sm-6">
                          <div className="mb-3">
                            <label className="form-label text-primary">
                              Full Name<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              placeholder="James Wally"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label text-primary">
                              Date of Birth<span className="required">*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              value={dob}
                              onChange={(e) => setDob(e.target.value)}
                            />
                          </div>
                          {/* <div className="mb-3">
                            <label className="form-label text-primary">
                              AMS ID<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={amsId}
                              onChange={(e) => setAmsId(e.target.value)}
                              placeholder="AMS ID"
                            />
                          </div> */}
                          <div className="mb-3">
                            <label className="form-label text-primary">
                              O/L ID<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={OLevelId}
                              onChange={(e) => setOLevelId(e.target.value)}
                              placeholder="O/L ID"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label text-primary">
                              Email<span className="required">*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="hello@example.com"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label text-primary">
                              Address<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              placeholder="1234 Main St"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-6">
                          <div className="mb-3">
                            <label className="form-label text-primary">
                              High School Name
                              <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={highschool}
                              onChange={(e) => setHighSchool(e.target.value)}
                              placeholder="High School Name"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label text-primary">
                              Gender<span className="required">*</span>
                            </label>
                            <select
                              className="form-control"
                              value={gender}
                              onChange={(e) => setGender(e.target.value)}
                            >
                              <option value="" disabled selected>
                                Select Gender
                              </option>
                              <option value="MALE">Male</option>
                              <option value="FEMALE">Female</option>
                              {/* <option value="OTHER">Other</option> */}
                            </select>
                          </div>
                          <div className="mb-3">
                            <label className="form-label text-primary">
                              Phone Number<span className="required">*</span>
                            </label>
                            <input
                              type="tel"
                              className="form-control"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              placeholder="+123456789"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label text-primary">
                              GPA<span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={gpa}
                              onChange={(e) => setGpa(e.target.value)}
                              placeholder="GPA"
                            />
                          </div>

                          <div className="mb-3">
                            <label className="form-label text-primary">
                              Password<span className="required">*</span>
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              placeholder="***********"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      className="btn btn-block btn-primary mt-3"
                      onClick={studentRegistration}
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <ColorRing
                          visible={true}
                          height="22"
                          width="22"
                          ariaLabel="color-ring-loading"
                          wrapperStyle={{}}
                          wrapperClass="color-ring-wrapper"
                          colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                        />
                      ) : (
                        "Register"
                      )}
                    </button>

                    <div className="mt-3">
                      Already have an account?{" "}
                      <a href="/login" className="text-primary float-end mb-4">
                        Login
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
