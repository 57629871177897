import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const url = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_VERSION}/users`;
const token = localStorage.getItem("campus-camer-user");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const initialState = {
  user: null,
};

export const login = createAsyncThunk("users/login", async (data, thunkAPI) => {
  try {
    const response = await axios.post(`${url}/login`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data) {
      localStorage.setItem(
        "campus-camer-user",
        response.data.token
      );
    }
    return response.data;
  } catch (error) {
    const message =
      (error.message && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${url}/forgot-password`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const newPassword = createAsyncThunk(
  "user/newPassword",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(`${url}/new-password/${data.token}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMatrix = createAsyncThunk("users/getMatrix", async (data, thunkAPI) => {
  try {
    const response = await axios.get(`${url}/matrix`, {
      params: data,
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    const message =
      (error.message && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getUser = createAsyncThunk("users/getUser", async (id, thunkAPI) => {
  try {
    const response = await axios.get(`${url}/details/${id}`);
    return response.data;
  } catch (error) {
    const message =
      (error.message && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addUser = createAsyncThunk("users/addUser", async (data, thunkAPI) => {
  try {
    const response = await axios.post(`${url}/register`, data, {
      headers: {
        "Content-Type": "application/json",
      }
    });
    return response.data;
  } catch (error) {
    const message =
      (error.message && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateUser = createAsyncThunk("users/updateUser", async (data, thunkAPI) => {
  try {
    const response = await axios.put(`${url}/update/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
      }
    });
    return response.data;
  } catch (error) {
    const message =
      (error.message && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getStudents = createAsyncThunk("users/getStudents", async (thunkAPI) => {
  try {
    const response = await axios.get(`${url}/students`);
    return response.data;
  } catch (error) {
    const message =
      (error.message && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getAdminOfficers = createAsyncThunk("users/getAdminOfficers", async (thunkAPI) => {
  try {
    const response = await axios.get(`${url}/admin-officers`);
    return response.data;
  } catch (error) {
    const message =
      (error.message && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});



export const authSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("campus-camer-user");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload.token;
    });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
