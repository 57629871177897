import React, { useEffect, useState } from "react";
import { updateUser } from "../../../redux/reducers/user";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { ColorRing } from "react-loader-spinner";
import uploadToS3 from "../../../utils/awsS3Upload";

const Settings = ({ user }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [amsId, setAmsId] = useState("");
  const [OLevelId, setOLevelId] = useState("");
  const [dob, setDob] = useState(null);
  const [highschool, setHighSchool] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [gpa, setGpa] = useState("");
  const [nationalIDNumber, setNationalIDNumber] = useState("");
  const [citizenship, setCitizenship] = useState("");
  //
  const [guardianName, setGuardianName] = useState("");
  const [guardianEmail, setGuardianEmail] = useState("");
  const [guardianPhone, seGuardianPhone] = useState("");
  const [guardianAge, setGuardianAge] = useState("");
  const [guardianAddress, setGuardianAddress] = useState("");

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Files
  const [profileImage, setProfileImage] = useState(null);
  const [preview, setPreview] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateStudent = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (profileImage) {
        const res = await uploadToS3(
          profileImage,
          "profile-image",
          profileImage?.name
        );
        if (res) {
          const data = {
            id: user._id,
            image: JSON.stringify(res),
            fullName: name,
            emailAddress: email,
            phoneNumber: phone,
            citizenship: citizenship,
            nationalIDNumber: nationalIDNumber,
            // student details
            studentDetails: {
              amsId: amsId,
              ordinaryLevelIdentificationNumber: OLevelId,
              dateOfBirth: dob,
              gender: gender,
              address: address,
              highSchoolName: highschool,
              gradesGPA: gpa,
              documents: [],
              // guardian details
              guardian: {
                guardianName: guardianName,
                guardianEmail: guardianEmail,
                guardianPhone: guardianPhone,
                guardianAge: guardianAge,
                guardianAddress: guardianAddress,
              },
            },
          };
          await dispatch(updateUser(data)).then((res) => {
            if (res.meta.requestStatus === "rejected") {
              toast.error(res.payload);
              setLoading(false);
              return;
            } else {
              toast.success(res.payload.message);
              setLoading(false);
              return;
            }
          });
        } else {
          toast.error("an error occured");
          setLoading(false);
          return;
        }
      } else {
        const data = {
          id: user._id,
          fullName: name,
          emailAddress: email,
          phoneNumber: phone,
          citizenship: citizenship,
          nationalIDNumber: nationalIDNumber,
          // student details
          studentDetails: {
            amsId: amsId,
            ordinaryLevelIdentificationNumber: OLevelId,
            dateOfBirth: dob,
            gender: gender,
            address: address,
            highSchoolName: highschool,
            gradesGPA: gpa,
            documents: [],
            // guardian details
            guardian: {
              guardianName: guardianName,
              guardianEmail: guardianEmail,
              guardianPhone: guardianPhone,
              guardianAge: guardianAge,
              guardianAddress: guardianAddress,
            },
          },
        };
        await dispatch(updateUser(data)).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setLoading(false);
            return;
          } else {
            toast.success(res.payload.message);
            setLoading(false);
            return;
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setName(user.fullName);
    setEmail(user.emailAddress);
    setPhone(user.phoneNumber);
    setNationalIDNumber(user.nationalIDNumber);
    setCitizenship(user.citizenship);
    setAmsId(user.studentDetails.amsId);
    setOLevelId(user.studentDetails.ordinaryLevelIdentificationNumber);
    setDob(user.studentDetails.dateOfBirth);
    setHighSchool(user.studentDetails.highSchoolName);
    setGender(user.studentDetails.gender);
    setAddress(user.studentDetails.address);
    setGpa(user.studentDetails.gradesGPA);

    // guardian info
    setGuardianName(user.studentDetails.guardian?.guardianName);
    setGuardianEmail(user.studentDetails.guardian?.guardianEmail);
    seGuardianPhone(user.studentDetails.guardian?.guardianPhone);
    setGuardianAge(user.studentDetails.guardian?.guardianAge);
    setGuardianAddress(user.studentDetails.guardian?.guardianAddress);
  }, [user]);

  return (
    <div id="settings" className="tab-pane fade">
      <div className="pt-3">
        <div className="settings-form">
          <h5 className="text-primary">Account Setting</h5>
          <form>
            <div class="row  mb-5 mt-5">
              <label class="form-label text-primary">Profile Iimage</label>
              <div class="avatar-upload">
                <div class="avatar-preview">
                  {preview && (
                    <img
                      src={preview}
                      alt="Preview"
                      style={{ width: "100px", height: "100px" }}
                    />
                  )}
                </div>
                <div class="change-btn mt-2 mb-lg-0 mb-3">
                  <input
                    type="file"
                    class="form-control d-none"
                    id="logoUpload"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleFileChange}
                  />

                  <label
                    for="logoUpload"
                    class="dlab-upload mb-0 btn btn-primary btn-sm"
                  >
                    Choose File
                  </label>
                  {/* <button
                        class="btn btn-success light remove-img ms-2 btn-sm"
                        onClick={textUpload}
                      >
                        Save
                      </button> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">
                  Full Name<span class="required">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Full Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">
                  Email<span class="required">*</span>
                </label>
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">
                  Phone Number<span class="required">*</span>
                </label>
                <input
                  type="number"
                  placeholder="Phone Number"
                  className="form-control"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">AMS ID</label>
                <input
                  type="text"
                  placeholder="AMS ID"
                  className="form-control"
                  value={amsId}
                  onChange={(e) => setAmsId(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">Citizenship</label>
                <input
                  type="text"
                  className="form-control"
                  value={citizenship}
                  onChange={(e) => setCitizenship(e.target.value)}
                  placeholder="Citizenship"
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">
                  Passport or NIC Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={nationalIDNumber}
                  onChange={(e) => setNationalIDNumber(e.target.value)}
                  placeholder="Passport or NIC Number"
                />
              </div>
            </div>

            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">O/L ID</label>
                <input
                  type="text"
                  placeholder="O/L ID"
                  className="form-control"
                  value={OLevelId}
                  onChange={(e) => setOLevelId(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">Date of Birth</label>
                <input
                  type="date"
                  placeholder="Date of Birth"
                  className="form-control"
                  value={new Date(dob).toISOString().split("T")[0]}
                  onChange={(e) => setDob(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">
                  High School Name
                </label>
                <input
                  type="text"
                  placeholder="High School Name"
                  className="form-control"
                  value={highschool}
                  onChange={(e) => setHighSchool(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary mb-2">Gender</label>
                <select
                  className="default-select wide form-control"
                  aria-label="Default select example"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option selected>Select Option</option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                  <option value="OTHER">Other</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">Grade / GPA</label>
                <input
                  type="text"
                  placeholder="High School Name"
                  className="form-control"
                  value={gpa}
                  onChange={(e) => setGpa(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">Address</label>
                <input
                  type="text"
                  placeholder="1234 Main St"
                  className="form-control"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>

            {/* <div className="mb-3">
              <label className="form-label text-primary">Address</label>
              <input
                type="text"
                placeholder="1234 Main St"
                className="form-control"
                address={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div> */}

            <h5 className="text-primary mt-5">Guidian Setting</h5>

            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">Full Name</label>
                <input
                  type="text"
                  placeholder="Full Name"
                  className="form-control"
                  value={guardianName}
                  onChange={(e) => setGuardianName(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">Email</label>
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={guardianEmail}
                  onChange={(e) => setGuardianEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">Phone Number</label>
                <input
                  type="number"
                  placeholder="Phone Number"
                  className="form-control"
                  value={guardianPhone}
                  onChange={(e) => seGuardianPhone(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">Age</label>
                <input
                  type="number"
                  placeholder="Age"
                  className="form-control"
                  value={guardianAge}
                  onChange={(e) => setGuardianAge(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label text-primary">Address 2</label>
              <input
                type="text"
                placeholder="1234 Main St"
                className="form-control"
                value={guardianAddress}
                onChange={(e) => setGuardianAddress(e.target.value)}
              />
            </div>
            {/* <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">City</label>
                <input type="text" className="form-control" />
              </div>
              <div className="mb-3 col-md-4">
                <label className="form-label text-primary">State</label>
                <select
                  className="form-control default-select wide"
                  id="inputState"
                >
                  <option selected="">Choose...</option>
                  <option>Option 1</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>
              </div>
              <div className="mb-3 col-md-2">
                <label className="form-label text-primary">Zip</label>
                <input type="text" className="form-control" />
              </div>
            </div> */}
            {/* <div className="mb-3">
              <div className="form-check custom-checkbox">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="gridCheck"
                />
                <label className="form-check-label form-label text-primary" for="gridCheck">
                  {" "}
                  Check me out
                </label>
              </div>
            </div> */}
            <button
              className="btn btn-primary"
              type="submit"
              onClick={handleUpdateStudent}
              disabled={loading ? true : false}
            >
              {loading ? (
                <ColorRing
                  visible={true}
                  height="22"
                  width="22"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />
              ) : (
                "Update"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Settings;
