import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { addProgram } from "../../../redux/reducers/program";
import { getUniversities } from "../../../redux/reducers/university";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import Select from "react-select";

const AddProgramComponent = forwardRef(
  ({ loading, setLoading, modalUniversityId }, ref) => {
    const [name, setName] = useState("");
    const [levelOfStudy, setLevelOfStudy] = useState("Undergraduate");
    const [description, setDescription] = useState("");
    const [duration, setDuration] = useState("");
    const [applicationDeadline, setApplicationDeadline] = useState(null);
    const [location, setLocation] = useState("");
    const [admissionRequirements, setAdmissionRequirements] = useState([]);
    const [conditionsForAcceptance, setConditionsForAcceptance] = useState([]);

    const [awardingBody, setAwardingBody] = useState("");
    const [tuitionFee, setTuitionFee] = useState("");
    const [initialDeposit, setInitialDeposit] = useState("");
    const [otherFees, setOtherFees] = useState("");
    const [teachingMode, setTeachingMode] = useState("");
    const [teachingMethods, setTeachingMethods] = useState("");
    const [mediumOfInstruction, setMediumOfInstruction] = useState("");
    const [academicYear, setAcademicYear] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [universities, setUniversities] = useState([]);
    const [university, setUniversity] = useState("");
    const [universitiesLoading, setUniversitiesLoading] = useState(false);

    const dispatch = useDispatch();

    const handleAddProgram = async (e) => {
      // e.preventDefault();
      try {
        setLoading(true);
        const data = {
          universityId: modalUniversityId ? modalUniversityId : university,
          name: name,
          levelOfStudy: levelOfStudy,
          description: description,
          duration: duration,
          applicationDeadline: applicationDeadline,
          location: location,
          admissionRequirements: admissionRequirements,
          conditionsForAcceptance: conditionsForAcceptance,
          //
          awardingBody,
          tuitionFee,
          initialDeposit,
          otherFees,
          teachingMode,
          teachingMethods,
          mediumOfInstruction,
          academicYear,
          startDate,
          endDate,
        };
        await dispatch(addProgram(data)).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setLoading(false);
            return;
          } else {
            toast.success(res.payload.message);
            window.location.href = "/programs";
            setLoading(false);
            return;
          }
        });
      } catch (error) {
        console.log("error", error);
        setLoading(false);
      }
    };

    // Expose the handleAddProgram function to the parent component
    useImperativeHandle(ref, () => ({
      addProgram: handleAddProgram,
    }));

    const handleAddRequirement = () => {
      setAdmissionRequirements([
        ...admissionRequirements,
        { title: "", value: "" },
      ]);
    };

    const handleAddCondition = () => {
      setConditionsForAcceptance([
        ...conditionsForAcceptance,
        { title: "", value: "" },
      ]);
    };

    const handleRequirementChange = (index, field, value) => {
      const newRequirements = [...admissionRequirements];
      newRequirements[index][field] = value;
      setAdmissionRequirements(newRequirements);
    };

    const handleConditionChange = (index, field, value) => {
      const newConditions = [...conditionsForAcceptance];
      newConditions[index][field] = value;
      setConditionsForAcceptance(newConditions);
    };

    const handleDeleteRequirement = (index) => {
      const newRequirements = admissionRequirements.filter(
        (_, i) => i !== index
      );
      setAdmissionRequirements(newRequirements);
    };

    const handleDeleteCondition = (index) => {
      const newConditions = conditionsForAcceptance.filter(
        (_, i) => i !== index
      );
      setConditionsForAcceptance(newConditions);
    };

    // Get universities
    const handleGetUniversities = async (e) => {
      try {
        setUniversitiesLoading(true);
        await dispatch(getUniversities()).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setUniversitiesLoading(false);
            return;
          } else {
            const structured = res.payload.map((item) => {
              return { label: item.name, value: item._id };
            });
            setUniversities(structured);
            setUniversitiesLoading(false);
            return;
          }
        });
      } catch (error) {
        console.log("error", error);
        setUniversitiesLoading(false);
      }
    };

    useEffect(() => {
      handleGetUniversities();
    }, []);

    return (
      <div>
        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-header">
                <h5 class="mb-0">Program Details</h5>
              </div>
              <div class="card-body">
                <div class="col-xl-12 col-lg-12">
                  <div className="row">
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Program Name<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Program Name"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label">
                        Level of Study<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Level of Study"
                        className="form-control"
                        value={levelOfStudy}
                        onChange={(e) => setLevelOfStudy(e.target.value)}
                      />
                    </div>
                    {/* <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Level of Study<span class="required">*</span>
                      </label>
                      <select
                        className="form-control"
                        value={levelOfStudy}
                        onChange={(e) => setLevelOfStudy(e.target.value)}
                      >
                        <option value="Undergraduate">Undergraduate</option>
                        <option value="Graduate">Graduate</option>
                        <option value="Diploma">Diploma</option>
                        <option value="Certificate">Certificate</option>
                        <option value="Other">Other</option>
                      </select>
                    </div> */}
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Duration<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Duration"
                        className="form-control"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Application Deadline<span class="required">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={
                          new Date(applicationDeadline)
                            .toISOString()
                            .split("T")[0]
                        }
                        onChange={(e) => setApplicationDeadline(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      {/* <label className="form-label text-primary">
                        Location<span class="required">*</span>
                      </label>
                      <select
                        className="form-control"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                      >
                        <option value="On campus">On campus</option>
                        <option value="Online">Online</option>
                        <option value="Hybrid">Hybrid</option>
                        <option value="Other">Other</option>
                      </select> */}
                      <label className="form-label text-primary">
                        Study Location<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Location"
                        className="form-control"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                      />
                    </div>

                    {!modalUniversityId && (
                      <div className="mb-3 col-md-6">
                        <label className="form-label text-primary">
                          University<span class="required">*</span>
                        </label>
                        <Select
                          options={universities}
                          onChange={(selected) => setUniversity(selected.value)}
                          isSearchable
                          placeholder="Select a university..."
                        />
                      </div>
                    )}
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Awarding Body<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Awarding Body"
                        className="form-control"
                        value={awardingBody}
                        onChange={(e) => setAwardingBody(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Tuition Fee<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Tuition Fee"
                        className="form-control"
                        value={tuitionFee}
                        onChange={(e) => setTuitionFee(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Initial Deposite<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Initial Deposite"
                        className="form-control"
                        value={initialDeposit}
                        onChange={(e) => setInitialDeposit(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Other Fees<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Other Fees"
                        className="form-control"
                        value={otherFees}
                        onChange={(e) => setOtherFees(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Teaching Mode<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Teaching Mode"
                        className="form-control"
                        value={teachingMode}
                        onChange={(e) => setTeachingMode(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Teaching Methods<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Teaching Methods"
                        className="form-control"
                        value={teachingMethods}
                        onChange={(e) => setTeachingMethods(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Program Start Date<span class="required">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={new Date(startDate).toISOString().split("T")[0]}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Program End Date<span class="required">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={new Date(endDate).toISOString().split("T")[0]}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Academic Year<span class="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Academic Year"
                        className="form-control"
                        value={academicYear}
                        onChange={(e) => setAcademicYear(e.target.value)}
                      />
                    </div>

                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Medium Of Instruction<span class="required">*</span>
                      </label>
                      <select
                        className="form-control"
                        value={mediumOfInstruction}
                        onChange={(e) => setMediumOfInstruction(e.target.value)}
                      >
                        <option value="">Select Medium</option>
                        <option value="ENGLISH">English</option>
                        <option value="FRENCH">French</option>
                        <option value="BILINGGUAL">Bilingual</option>
                      </select>
                    </div>

                    <div className="mb-3 col-md-12">
                      <label className="form-label text-primary">
                        Description
                      </label>
                      <textarea
                        placeholder="Description"
                        className="form-control"
                        rows="6"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h5 class="mb-0">Admission Requirements</h5>
            </div>
            <div class="card-body">
              {admissionRequirements.map((req, index) => (
                <div key={index} className="row mb-3">
                  <div className="col-md-5">
                    <label className="form-label">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      value={req.title}
                      onChange={(e) =>
                        handleRequirementChange(index, "title", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-5">
                    <label className="form-label">Value</label>
                    <input
                      type="text"
                      className="form-control"
                      value={req.value}
                      onChange={(e) =>
                        handleRequirementChange(index, "value", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-2 d-flex align-items-end">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => handleDeleteRequirement(index)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
              <button
                type="button"
                className="btn btn-secondary mb-3"
                onClick={handleAddRequirement}
              >
                Add Requirement
              </button>
            </div>
          </div>
        </div>

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h5 class="mb-0">Conditions for Acceptance</h5>
            </div>
            <div class="card-body">
              {conditionsForAcceptance.map((cond, index) => (
                <div key={index} className="row mb-3">
                  <div className="col-md-5">
                    <label className="form-label">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      value={cond.title}
                      onChange={(e) =>
                        handleConditionChange(index, "title", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-5">
                    <label className="form-label">Value</label>
                    <input
                      type="text"
                      className="form-control"
                      value={cond.value}
                      onChange={(e) =>
                        handleConditionChange(index, "value", e.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-2 d-flex align-items-end">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => handleDeleteCondition(index)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
              <button
                type="button"
                className="btn btn-secondary mb-3"
                onClick={handleAddCondition}
              >
                Add Condition
              </button>

              {/* <div>
              <button
                className="btn btn-primary"
                type="submit"
                onClick={handleAddProgram}
                disabled={loading}
              >
                {loading ? (
                  <ColorRing
                    visible={true}
                    height="22"
                    width="22"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />
                ) : (
                  "Save Changes"
                )}
              </button>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default AddProgramComponent;
