import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const url = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_VERSION}/payments`;
const token = localStorage.getItem("campus-camer-user");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const initialState = {};

export const payRegFee = createAsyncThunk("payments/payRegFee", async (data, thunkAPI) => {
    try {
        const response = await axios.post(`${url}/tranzak`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getPayments = createAsyncThunk("payments/getPayments", async (thunkAPI) => {
    try {
        const response = await axios.get(`${url}`);
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getApplicationPayments = createAsyncThunk("payments/getApplicationPayments", async (id, thunkAPI) => {
    try {
        const response = await axios.get(`${url}/application/${id}`);
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getStudentPayments = createAsyncThunk("payments/getStudentPayments", async (id, thunkAPI) => {
    try {
        const response = await axios.get(`${url}/student/${id}`);
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});


export const paymentsSlice = createSlice({
    name: "payments",
    initialState,
    reducers: {},
    extraReducers: (builder) => { },
});

export const { } = paymentsSlice.actions;
export default paymentsSlice.reducer;
