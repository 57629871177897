import React from "react";
import styles from "./style.module.css";
import { formatMoney } from "../../utils/formatMoney";
import moment from "moment/moment";

const ConditionalLetter = ({ student, program, application, university }) => {
  return (
    <div
      id="conditional_offer_letter"
      className={styles.conditional_offer_letter}
    >
      <div className={styles.logoDiv}>
        <img
          id="letter_logo"
          src={university?.image && JSON.parse(university.image).Location}
          className={styles.logo}
        />
      </div>
      <h3>CONDITIONAL OFFER LETTER</h3>
      <p>Dear {student.fullName}.</p>

      <p>
        Application Number <strong>{application.uniqueAppID}</strong> Please use
        this number anytime you need to contact us Thank you for your
        application to study <strong>{program.name}</strong> at{" "}
        <strong>{application.universityName}</strong> for{" "}
        <strong>{program.academicYear}</strong> intake.
      </p>

      <p>
        I am pleased to confirm that we have assessed your application and we
        are pleased to issue you a conditional offer for a place on the
        programme provided you meet the following conditions upon enrollment on
        campus.
      </p>

      <table>
        <thead>
          <tr>
            <td>CONDITIONS</td>
            <td>COMMENTS</td>
          </tr>
        </thead>
        <tbody>
          {program.conditionsForAcceptance.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.title}</td>
                <td>{item.value}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <h4>Important information about your programme</h4>

      <table>
        <tbody>
          <tr>
            <td>Course Name</td>
            <td>{program.name}</td>
          </tr>
          <tr>
            <td>Awarding Body</td>
            <td>{program.awardingBody}</td>
          </tr>
          <tr>
            <td>Total Tuition Fee</td>
            <td>{formatMoney(program.tuitionFee)}</td>
          </tr>
          <tr>
            <td>Deposit Required</td>
            <td>{formatMoney(program.initialDeposit)}</td>
          </tr>
          <tr>
            <td>Other Fees</td>
            <td>{program.otherFees}</td>
          </tr>
          <tr>
            <td>Study Location</td>
            <td>{program.location}</td>
          </tr>
          <tr>
            <td>Attendance Mode</td>
            <td>{program.teachingMode}</td>
          </tr>
          <tr>
            <td>Teaching Methods</td>
            <td>{program.teachingMethods}</td>
          </tr>
          <tr>
            <td>Academic Year</td>
            <td>{program.academicYear}</td>
          </tr>
          <tr>
            <td>Programme Start Date</td>
            <td>{moment(program.startDate).format("DD-MM-YYYY")}</td>
          </tr>
          <tr>
            <td>Programme End Date</td>
            <td>{moment(program.endDate).format("DD-MM-YYYY")}</td>
          </tr>
        </tbody>
      </table>

      <h4>Next Steps: How To Meet Your Conditions</h4>

      <p>
        Once you have satisfied all of the above conditions, the university will
        issue you a final acceptance letter as well as other supporting
        documents within five working days. Your offer maybe withdrawn if you do
        not meet the entry requirements.
      </p>

      <p>
        Please be aware, places on all our courses are limited, and are offered
        on a first-come, first-served basis. This offer does not guarantee you a
        place, therefore we recommend you to fulfil the conditions listed above
        as soon as possible.
      </p>

      <p>We look forward to hearing from you in the nearest future.</p>

      <h3 className={styles.signatory}>
        <strong>
          <i>{university?.signatory}</i>
        </strong>
      </h3>
      <p>
        <span>Admission Officer</span>
      </p>
    </div>
  );
};

export default ConditionalLetter;
