import React, { useState, useEffect } from "react";
import Layout from "../../layouts/Layout";
import { addUser } from "../../redux/reducers/user";
import { getUniversities } from "../../redux/reducers/university";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { ColorRing } from "react-loader-spinner";
import uploadToS3 from "../../utils/awsS3Upload";
import CustomMultiSelect from "../../components/inputs/CustomMultiSelect/CustomMultiSelect";

const AddAdminPage = () => {
  // Common fields
  const [role, setRole] = useState("");
  const [image, setImage] = useState("");
  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nationalIDNumber, setNationalIDNumber] = useState("");
  const [citizenship, setCitizenship] = useState("");
  const [authorizationLevel, setAuthorizationLevel] = useState(4);
  const [password, setPassword] = useState("");

  // Admission Officer-specific fields
  const [assignedUniversities, setAssignedUniversities] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [universityLoading, setUniversityLoading] = useState(false);

  const dispatch = useDispatch();

  // Files
  const [profileImage, setProfileImage] = useState(null);
  const [preview, setPreview] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const extractIds = (array) => {
    return array?.map((item) => item?._id);
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setLoading(true);
      const res = await uploadToS3(
        profileImage,
        "profile-image",
        profileImage?.name
      );
      if (res) {
        const data = {
          role: role,
          image: JSON.stringify(res),
          fullName: fullName,
          emailAddress: emailAddress,
          phoneNumber: phoneNumber,
          citizenship: citizenship,
          nationalIDNumber: nationalIDNumber,
          authorizationLevel: authorizationLevel,
          password: password,
          // admission officer details
          admissionOfficerDetails: {
            assignedUniversities: extractIds(assignedUniversities),
          },
        };
        await dispatch(addUser(data)).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setLoading(false);
            return;
          } else {
            toast.success(res.payload.message);
            window.location.href = "/admins";
            setLoading(false);
            return;
          }
        });
      } else {
        toast.error("Please upload an image");
        setLoading(false);
        return;
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleUniversitiesChange = (selectedOptions) => {
    setAssignedUniversities(selectedOptions);
  };

  const handleGetUniversities = async (e) => {
    try {
      setUniversityLoading(true);
      await dispatch(getUniversities()).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          setUniversityLoading(false);
          return;
        } else {
          setUniversities(res.payload);
          setUniversityLoading(false);
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleGetUniversities();
  }, []);

  return (
    <Layout>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h5 class="mb-0">Admin Details</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="row  mb-5 mt-5">
                  <label class="form-label text-primary">
                    Profile Iimage<span class="required">*</span>
                  </label>
                  <div class="avatar-upload">
                    <div class="avatar-preview">
                      {preview && (
                        <img
                          src={preview}
                          alt="Preview"
                          style={{ width: "100px", height: "100px" }}
                        />
                      )}
                    </div>
                    <div class="change-btn mt-2 mb-lg-0 mb-3">
                      <input
                        type="file"
                        class="form-control d-none"
                        id="logoUpload"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleFileChange}
                      />

                      <label
                        for="logoUpload"
                        class="dlab-upload mb-0 btn btn-primary btn-sm"
                      >
                        Choose File
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12">
                  <div className="row">
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Role<span className="required">*</span>
                      </label>
                      <select
                        className="form-control"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                      >
                        <option value="">Select Role</option>
                        <option value="AECO_ADMIN">AECO ADMIN</option>
                        <option value="ADMISSION_OFFICER">
                          UNIVERSITY ADMIN
                        </option>
                        <option value="COUNSELLOR">COUNSELLOR</option>
                      </select>
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Full Name<span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Full Name"
                        className="form-control"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Citizenship
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={citizenship}
                        onChange={(e) => setCitizenship(e.target.value)}
                        placeholder="Citizenship"
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Passport or NIC Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={nationalIDNumber}
                        onChange={(e) => setNationalIDNumber(e.target.value)}
                        placeholder="Passport or NIC Number"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Email<span className="required">*</span>
                      </label>
                      <input
                        type="email"
                        placeholder="Email"
                        className="form-control"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Phone Number<span className="required">*</span>
                      </label>
                      <input
                        type="number"
                        placeholder="Phone Number"
                        className="form-control"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Authorization Level
                      </label>
                      <p className="form-label text-primary">
                        Level 1: Can create both conditional && final offer
                        letters
                      </p>
                      <p className="form-label text-primary">
                        Level 2: Can create only conditional offer letters
                      </p>
                      <p className="form-label text-primary">
                        Level 3: Counsellors
                      </p>
                      <select
                        className="form-control"
                        value={authorizationLevel}
                        onChange={(e) => setAuthorizationLevel(e.target.value)}
                      >
                        <option value="">Select Level</option>
                        <option value={1}>Level 1</option>
                        <option value={2}>Level 2</option>
                        <option value={3}>Level 3</option>
                      </select>
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Password<span className="required">*</span>
                      </label>
                      <input
                        type="password"
                        placeholder="Password"
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-6">
                      <label className="form-label text-primary">
                        Assigned Universities
                      </label>
                      <CustomMultiSelect
                        options={universities}
                        selectedOptions={assignedUniversities}
                        onChange={handleUniversitiesChange}
                      />
                    </div>
                  </div>
                  <button
                    className="btn btn-primary mt-5"
                    type="submit"
                    onClick={handleAddUser}
                    disabled={loading}
                  >
                    {loading ? (
                      <ColorRing
                        visible={true}
                        height="22"
                        width="22"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                      />
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddAdminPage;
