import React from "react";
import moment from "moment";
import { IoChevronForward } from "react-icons/io5";

const About = ({ program }) => {
  return (
    <div id="about" class="tab-pane fade active show">
      <div class="pt-4 profile-personal-info">
        <h5 class="text-primary mb-4">More Information</h5>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Name <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{program.name}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Level of Study <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{program.levelOfStudy}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Duration <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{program.duration}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Application Deadline <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>
              {moment(program.applicationDeadline).format("DD-MM-YYYY")}
            </span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Location <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{program.location}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Description <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{program.description}</span>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <a
          href={`/program-details?=${program?._id}`}
          className="text-secondary"
        >
          More Info <IoChevronForward />
        </a>
      </div>
    </div>
  );
};

export default About;
