import React, { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import Tab from "../../components/student/tab/Tab";
import Applications from "../../components/application/applications/Applications";
import Payments from "../../components/payments/Payments";

import Loader from "../../components/Loader/Loader";
import { useLocation } from "react-router-dom";
import { getUser } from "../../redux/reducers/user";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

const StudentDetails = () => {
  const { search } = useLocation();
  const id = search.split("?=")[1];

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleGetUser = async (e) => {
    try {
      if (id) {
        setLoading(true);
        await dispatch(getUser(id)).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setLoading(false);
            return;
          } else {
            setUser(res.payload);
            setLoading(false);
            return;
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleGetUser();
  }, [id]);

  return (
    <Layout>
      <div className="row">
        <div className="col-xl-12">
          <div className="card h-auto">
            <div className="card-header p-0">
              <div className="user-bg w-100">
                <div className="user-svg">
                  <svg
                    width="264"
                    height="109"
                    viewBox="0 0 264 109"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.0107422"
                      y="0.6521"
                      width="263.592"
                      height="275.13"
                      rx="20"
                      fill="#FCC43E"
                    />
                  </svg>
                </div>
                <div className="user-svg-1">
                  <svg
                    width="264"
                    height="59"
                    viewBox="0 0 264 59"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      y="0.564056"
                      width="263.592"
                      height="275.13"
                      rx="20"
                      fill="#FB7D5B"
                    />
                  </svg>
                </div>
              </div>
            </div>
            {loading || !user ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader />
              </div>
            ) : (
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div className="user">
                    <div className="user-media">
                      <img
                        src={
                          (user.image && JSON.parse(user?.image)?.Location) ||
                          "images/avatar/9.jpg"
                        }
                        alt=""
                        className="avatar avatar-xxl"
                      />
                    </div>
                    <div>
                      <h2 className="mb-0">{user.fullName}</h2>
                      <p className="text-primary font-w600">
                        {user.role === "STUDENT"
                          ? "Student"
                          : user.role === "ADMIN"
                          ? "Admin"
                          : "Admission Officer"}
                      </p>
                    </div>
                  </div>
                  <div className="dropdown custom-dropdown">
                    <div
                      className="btn sharp tp-btn "
                      data-bs-toggle="dropdown"
                    >
                      <svg
                        width="24"
                        height="6"
                        viewBox="0 0 24 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.0012 0.359985C11.6543 0.359985 11.3109 0.428302 10.9904 0.561035C10.67 0.693767 10.3788 0.888317 10.1335 1.13358C9.88829 1.37883 9.69374 1.67 9.56101 1.99044C9.42828 2.31089 9.35996 2.65434 9.35996 3.00119C9.35996 3.34803 9.42828 3.69148 9.56101 4.01193C9.69374 4.33237 9.88829 4.62354 10.1335 4.8688C10.3788 5.11405 10.67 5.3086 10.9904 5.44134C11.3109 5.57407 11.6543 5.64239 12.0012 5.64239C12.7017 5.64223 13.3734 5.36381 13.8686 4.86837C14.3638 4.37294 14.6419 3.70108 14.6418 3.00059C14.6416 2.3001 14.3632 1.62836 13.8677 1.13315C13.3723 0.637942 12.7004 0.359826 12 0.359985H12.0012ZM3.60116 0.359985C3.25431 0.359985 2.91086 0.428302 2.59042 0.561035C2.26997 0.693767 1.97881 0.888317 1.73355 1.13358C1.48829 1.37883 1.29374 1.67 1.16101 1.99044C1.02828 2.31089 0.959961 2.65434 0.959961 3.00119C0.959961 3.34803 1.02828 3.69148 1.16101 4.01193C1.29374 4.33237 1.48829 4.62354 1.73355 4.8688C1.97881 5.11405 2.26997 5.3086 2.59042 5.44134C2.91086 5.57407 3.25431 5.64239 3.60116 5.64239C4.30165 5.64223 4.97339 5.36381 5.4686 4.86837C5.9638 4.37294 6.24192 3.70108 6.24176 3.00059C6.2416 2.3001 5.96318 1.62836 5.46775 1.13315C4.97231 0.637942 4.30045 0.359826 3.59996 0.359985H3.60116ZM20.4012 0.359985C20.0543 0.359985 19.7109 0.428302 19.3904 0.561035C19.07 0.693767 18.7788 0.888317 18.5336 1.13358C18.2883 1.37883 18.0937 1.67 17.961 1.99044C17.8283 2.31089 17.76 2.65434 17.76 3.00119C17.76 3.34803 17.8283 3.69148 17.961 4.01193C18.0937 4.33237 18.2883 4.62354 18.5336 4.8688C18.7788 5.11405 19.07 5.3086 19.3904 5.44134C19.7109 5.57407 20.0543 5.64239 20.4012 5.64239C21.1017 5.64223 21.7734 5.36381 22.2686 4.86837C22.7638 4.37294 23.0419 3.70108 23.0418 3.00059C23.0416 2.3001 22.7632 1.62836 22.2677 1.13315C21.7723 0.637942 21.1005 0.359826 20.4 0.359985H20.4012Z"
                          fill="#A098AE"
                        />
                      </svg>
                    </div>
                    <div className="dropdown-menu dropdown-menu-end">
                      <a
                        className="dropdown-item"
                        target="_blank"
                        href={`mailto:${user.emailAddress}`}
                      >
                        Send Email
                      </a>
                      <a
                        className="dropdown-item"
                        target="_blank"
                        href={`https://api.whatsapp.com/send?phone=${user.phoneNumber}`}
                      >
                        WhatsApp
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-xl-3 col-xxl-6 col-sm-6">
                    <ul className="student-details">
                      <li className="me-2">
                        <a className="icon-box bg-secondary">
                          <img src="images/profile.svg" alt="" />
                        </a>
                      </li>
                      <li>
                        <span>AMS ID:</span>
                        <h5 className="mb-0">{user.studentDetails.amsId}</h5>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xl-3 col-xxl-6 col-sm-6">
                    <ul className="student-details">
                      <li className="me-2">
                        <a className="icon-box bg-secondary">
                          <img src="images/svg/location.svg" alt="" />
                        </a>
                      </li>
                      <li>
                        <span>Ordinary Level ID:</span>
                        <h5 className="mb-0">
                          {
                            user.studentDetails
                              .ordinaryLevelIdentificationNumber
                          }
                        </h5>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xl-3 col-xxl-6 col-sm-6">
                    <ul className="student-details">
                      <li className="me-2">
                        <a className="icon-box bg-secondary">
                          <img src="images/svg/phone.svg" alt="" />
                        </a>
                      </li>
                      <li>
                        <span>Phone:</span>
                        <h5 className="mb-0">{user.phoneNumber}</h5>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xl-3 col-xxl-6 col-sm-6">
                    <ul className="student-details">
                      <li className="me-2">
                        <a className="icon-box bg-secondary">
                          <img src="images/svg/email.svg" alt="" />
                        </a>
                      </li>
                      <li>
                        <span>Email:</span>
                        <h5 className="mb-0">{user.emailAddress}</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>

          {loading || !user ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loader />
            </div>
          ) : (
            <Tab user={user} />
          )}

          {/* Applications */}
          <Applications filter="STUDENT" id={id} />

          {/* Payments */}
          <Payments filter="STUDENT" id={id} />
        </div>
      </div>
    </Layout>
  );
};

export default StudentDetails;
