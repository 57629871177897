import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { getCities } from "../../../redux/reducers/university";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

const images = [
  { name: "bafoussam", img: "/images/cities/bafoussam.jpeg" },
  { name: "bamenda", img: "/images/cities/bamenda.JPG" },
  { name: "douala", img: "/images/cities/douala.JPG" },
  { name: "ebolowa", img: "/images/cities/ebolowa.jpg" },
  { name: "garoua", img: "/images/cities/garoua.jpg" },
  { name: "limbe", img: "/images/cities/limbe.png" },
  { name: "maroua", img: "/images/cities/maroua.jpg" },
  { name: "yaounde", img: "/images/cities/yaounde.jpg" },
  { name: "buea", img: "/images/cities/buea.jpg" },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(3);

  // data
  const [citiesArray, setCitiesArray] = useState([]);
  const dispatch = useDispatch();

  const handleGetCities = async () => {
    try {
      await dispatch(getCities()).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          return;
        } else {
          setCitiesArray(res.payload);
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleGetCities();
  }, []);

  useEffect(() => {
    const updateItemsToShow = () => {
      const width = window.innerWidth;
      if (width <= 480) {
        setItemsToShow(1);
      } else if (width <= 768) {
        setItemsToShow(2);
      } else {
        setItemsToShow(3);
      }
    };

    updateItemsToShow();
    window.addEventListener("resize", updateItemsToShow);
    return () => window.removeEventListener("resize", updateItemsToShow);
  }, []);

  const next = () => {
    if (currentIndex < citiesArray.length - itemsToShow) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const getUniqueImage = (city) => {
    const image = images.find((img) => img.name === city.toLowerCase());
    if (!image) {
      console.warn(`Image not found for city: ${city}`);
    }
    return image?.img || "/images/universities/image4.jpg";
  };

  return (
    <div className={styles.carousel}>
      <div className={styles.images}>
        <div className={styles.imageContainer}>
          {citiesArray
            .slice(currentIndex, currentIndex + itemsToShow)
            .map((item, index) => (
              <a
                key={index}
                className={styles.card}
                style={{
                  // backgroundImage: `url("/images/landing/programs_near.png")`,
                  backgroundImage: `url(${getUniqueImage(item.city)})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                href={`/search-program?${new URLSearchParams({
                  levelOfStudy: JSON.stringify([item.city]),
                }).toString()}`}
              >
                <div className={styles.bottomRight}>
                  <p>{item.universityCount}+ Institutions</p>
                </div>
                <div className={styles.centerBottom}>
                  <p>{item.city}</p>
                </div>
              </a>
            ))}
        </div>
        <div className={styles.btns}>
          <button
            className={styles.button}
            onClick={prev}
            disabled={currentIndex === 0}
          >
            <FaChevronLeft />
          </button>
          <button
            className={styles.button}
            onClick={next}
            disabled={currentIndex >= citiesArray.length - itemsToShow}
          >
            <FaChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
