import React from "react";

const Documents = ({ university }) => {
  return (
    <div id="documents" class="tab-pane fade">
      <div class="pt-4 profile-personal-info">
        <h5 class="text-primary mb-4">Documents</h5>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Logo <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>
              <a href={JSON.parse(university?.image)?.Location} target="_blank">
                DOWNLOAD
              </a>
            </span>
            <div>
              <img
                src={JSON.parse(university?.image)?.Location}
                alt="Preview"
                style={{ maxWidth: "100px", height: "auto", marginTop: 10 }}
              />
            </div>
          </div>
        </div>
        <div class="row mb-2 mt-5">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Cover Photo <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>
              <a
                href={JSON.parse(university?.coverPhoto)?.Location}
                target="_blank"
              >
                DOWNLOAD
              </a>
            </span>
            <div>
              <img
                src={JSON.parse(university?.coverPhoto)?.Location}
                alt="Preview"
                style={{ maxWidth: "400px", height: "auto", marginTop: 10 }}
              />
            </div>
          </div>
        </div>
        <div class="row mb-2 mt-5">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Letter Head <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>
              <a
                href={JSON.parse(university?.letterHead)?.Location}
                target="_blank"
              >
                DOWNLOAD
              </a>
            </span>
            <div>
              <img
                src={JSON.parse(university?.letterHead)?.Location}
                alt="Preview"
                style={{ maxWidth: "400px", height: "auto", marginTop: 10 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
