import React from "react";
import styles from "./style.module.css";
import moment from "moment";

const FinalLetter = ({ student, program, application, university }) => {
  return (
    <div id="final_offer_letter" className={styles.final_offer_letter}>
      <div className={styles.letterHeadDiv}>
        <img
          id="letter_head"
          src={
            university?.letterHead && JSON.parse(university.letterHead).Location
          }
          className={styles.letterHead}
          style={{
            width: 714,
            height: 150,
          }}
        />
      </div>

      <h3>FINAL ACCEPTANCE LETTER</h3>
      <p>
        <strong>{university?.name}</strong> announces that the admission
        procedure of the following applicant:
      </p>

      <p>
        <strong>Family Name:</strong> {student.fullName.split(" ")[0]}
      </p>
      <p>
        <strong>First Name:</strong> {student.fullName.split(" ")[1]}
      </p>
      <p>
        <strong>Citizenship:</strong> {student.citizenship}
      </p>
      <p>
        <strong>Passport or NIC Number:</strong> {student.nationalIDNumber}
      </p>

      <p>
        To the full time <strong>{program.levelOfStudy}</strong> course in{" "}
        <strong>{program.name}</strong> was successful, and the applicant has
        successfully completed all conditions of acceptance into the above study
        programme.
      </p>

      <p>
        This document guarantees the final acceptance of the applicant to the{" "}
        <strong>{program?.name}</strong> at <strong>{university?.name}</strong>.
      </p>

      <p>
        <strong>Registration Number:</strong> {application.uniqueAppID}
      </p>
      <p>
        <strong>Study Location Address:</strong> {program.location}
      </p>
      <p>
        <strong>Study Mode:</strong> {program.teachingMode}
      </p>
      <p>
        <strong>Start Date:</strong>{" "}
        {moment(program.startDate).format("DD-MM-YYYY")}
      </p>
      <p>
        <strong>End Date:</strong>{" "}
        {moment(program.endDate).format("DD-MM-YYYY")}
      </p>

      <p>
        This Final Acceptance Letter was issued to enable the student obtain the
        entry visa to Cameroon and to collect the resident permit for the
        purpose of studies where need be as well as to enable the applicant
        complete enrollment at the university and programme.
      </p>

      <p>Email: {university?.contactInformation.email}</p>

      <div className={styles.footer}>
        <p>Yours Faithfully</p>

        <p>
          <p>
            <strong>{university?.signatory}</strong>
          </p>
          <p>
            <strong>Director of Admissions</strong>
          </p>
          <p>
            <strong>[Signature]</strong>
          </p>
        </p>

        <p>
          <p>
            <strong>Admissions Office</strong>
          </p>
          <p>
            <strong>{university?.name}</strong> | {university?.location.city} |{" "}
            {university?.location.country}
          </p>
        </p>
      </div>
    </div>
  );
};

export default FinalLetter;
