import React, { createContext, useState, useEffect } from "react";
import { decodeToken } from "react-jwt";

export const AuthContext = createContext();

export default ({ children }) => {
  const [role, setRole] = useState("");
  const [userId, setUserId] = useState("");
  const [username, setUnsername] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState(null);
  const [token, setToken] = useState("");
  const [authorizationLevel, setAuthorizationLevel] = useState("");
  const [assignedUniversities, setAssignedUniversities] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);

  const userToken = localStorage.getItem("campus-camer-user");
  useEffect(() => {
    if (userToken) {
      const decodedToken = decodeToken(userToken);

      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        console.log('Token is expired');
        localStorage.removeItem("campus-camer-user");
        setAuthenticated(false);
        return;
      }

      setRole(decodedToken?.role);
      setUserId(decodedToken?.id);
      setUnsername(decodedToken?.fullName);
      setEmail(decodedToken?.emailAddress);
      setToken(userToken);
      setAuthorizationLevel(decodedToken?.authorizationLevel);
      setAssignedUniversities(decodedToken?.assignedUniversities);
      setAuthenticated(true);
    }
  }, [userToken]);

  return (
    <AuthContext.Provider
      value={{
        role,
        setRole,
        userId,
        setUserId,
        username,
        setUnsername,
        email,
        setEmail,
        image,
        setImage,
        token,
        setToken,
        authenticated,
        setAuthenticated,
        authorizationLevel,
        setAuthorizationLevel,
        assignedUniversities,
        setAssignedUniversities
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
