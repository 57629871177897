import React from "react";
import moment from "moment";

const About = ({ user }) => {
  return (
    <div id="about" class="tab-pane fade active show">
      {/* <div class="profile-about-me">
        <div class="pt-4 border-bottom-1 pb-3">
          <h5 class="text-primary">About Me</h5>
          <p class="mb-2">
            A wonderful serenity has taken possession of my entire soul, like
            these sweet mornings of spring which I enjoy with my whole heart. I
            am alone, and feel the charm of existence was created for the bliss
            of souls like mine.I am so happy, my dear friend, so absorbed in the
            exquisite sense of mere tranquil existence, that I neglect my
            talents.
          </p>
          <p>
            A collection of textile samples lay spread out on the table - Samsa
            was a travelling salesman - and above it there hung a picture that
            he had recently cut out of an illustrated magazine and housed in a
            nice, gilded frame.
          </p>
        </div>
      </div>
      <div class="profile-skills mb-5">
        <h5 class="text-primary mb-2">Skills</h5>
        <a href="javascript:void(0);" class="btn btn-primary light btn-xs mb-1">
          Admin
        </a>
        <a href="javascript:void(0);" class="btn btn-primary light btn-xs mb-1">
          Dashboard
        </a>
        <a href="javascript:void(0);" class="btn btn-primary light btn-xs mb-1">
          Photoshop
        </a>
        <a href="javascript:void(0);" class="btn btn-primary light btn-xs mb-1">
          Bootstrap
        </a>
        <a href="javascript:void(0);" class="btn btn-primary light btn-xs mb-1">
          Responsive
        </a>
        <a href="javascript:void(0);" class="btn btn-primary light btn-xs mb-1">
          Crypto
        </a>
      </div>
      <div class="profile-lang  mb-5">
        <h5 class="text-primary mb-2">Language</h5>
        <a href="javascript:void(0);" class="text-muted pe-3 f-s-16">
          <i class="flag-icon flag-icon-us"></i> English
        </a>
        <a href="javascript:void(0);" class="text-muted pe-3 f-s-16">
          <i class="flag-icon flag-icon-fr"></i> French
        </a>
        <a href="javascript:void(0);" class="text-muted pe-3 f-s-16">
          <i class="flag-icon flag-icon-bd"></i> Bangla
        </a>
      </div> */}
      <div class="pt-4 profile-personal-info">
        <h5 class="text-primary mb-4">Personal Information</h5>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Date of Birth <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>
              {moment(user.studentDetails.dateOfBirth).format("DD-MM-YYYY")}
            </span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Gender <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{user.studentDetails.gender}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Address <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{user.studentDetails.address}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              High School Name <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{user.studentDetails.highSchoolName}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Grades / GPA <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{user.studentDetails.gradesGPA}</span>
          </div>
        </div>
      </div>

      <div class="pt-4 profile-personal-info">
        <h5 class="text-primary mb-4">Guardian Information</h5>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Name <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{user.studentDetails.guardian?.guardianName}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Email <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{user.studentDetails.guardian?.guardianEmail}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Phone Number <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{user.studentDetails.guardian?.guardianPhone}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Age <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{user.studentDetails.guardian?.guardianAge}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Location <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{user.studentDetails.guardian?.guardianAddress}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
