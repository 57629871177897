import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import styles from "./styles.module.css";
import { Link, useNavigate } from "react-router-dom";
import Carousel from "../../components/landing/carousel/Carousel";
import Testimonial from "../../components/landing/testimonials/Carousel";
import FeaturedInstitutions from "../../components/landing/featuredInstitutions/FeaturedInstitutions";
import { FaChevronRight, FaFacebookF } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import Accordion from "../../components/landing/accordion/Accordion";
import Widget from "../../components/programSearch/widget/Widget";

const data = [
  {
    numbering: "1",
    title: "Click on the Apply button",
    body: `
    Ready to start your journey towards a brighter future? Simply 
    click the "Apply" button to begin your application process. 
    This will take you to the next step where you can choose from a wide range of 
    schools and programs that suit your interests and career goals.
    `,
  },
  {
    numbering: "2",
    title: "Choose a School",
    body: `
    Explore our comprehensive list of accredited schools. 
    You can filter your options based on location, program, 
    school fees, progra, durations and the programs levels they offer.`,
  },
  {
    numbering: "3",
    title: "Select a study program",
    body: `
    It's time to pick a study program. Whether you're interested in 
    engineering, business, arts, or any other field, we have a diverse range of programs to 
    suit your aspirations. Make an informed decision and select the program that aligns with your career goals.
    `,
  },
  {
    numbering: "4",
    title: "Apply for admission",
    body: `
    You've chosen your school and program—now it's time to apply. Application for a program is straightforward,
    just click on the "Apply Now" button for each program, and the application will be automatically sent to the university.
    Make sure you complete your student profile and fill out all information on your profile to stand a better chance of being
    accepted to the program.
    `,
  },
  {
    numbering: "5",
    title: "Get an admission letter",
    body: `
    After submitting your application, our team will review it and keep you updated on the progress. You will receive a 
    conditional offer letter that outlines the conditions to be me to be accepted for that program. If your 
    application is successful, you will receive an official admission letter from the school. This letter will contain 
    all the details you need to finalize your enrollment and start preparing for your new academic adventure.
    `,
  },
];

const sections = [
  "why_campus_camer",
  "programs_near_you",
  "featured_programs",
  "registration_steps",
  // "testimonial",
];

const Landing = () => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          const threshold = 50;
          if (rect.top <= threshold && rect.bottom >= threshold) {
            setActiveSection(section);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Search Variables
  const [program, setProgram] = useState([]);
  const [levelOfStudy, setLevelOfStudy] = useState([]);
  const [location, setLocation] = useState([]);

  const handleSearchPrograms = () => {
    try {
      const params = {
        name: JSON.stringify(program),
        location: JSON.stringify(location),
        levelOfStudy: JSON.stringify(levelOfStudy),
      };
      navigate(`/search-program?${new URLSearchParams(params).toString()}`);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className={styles.hero}>
        <div className="container">
          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              <div>
                <h1>Your Gateway to Educational Success in Cameroon</h1>
                <p>
                  From High School to PhD, discover a wide range of study
                  programs available in Cameroon. Start your academic journey
                  locally and achieve your dreams with CampusCamer.
                </p>
                <h3>Alredy a member?</h3>
                <div className={styles.btns}>
                  <Link to="/search-program" className={styles.btnApply}>
                    Apply Now
                  </Link>
                  <Link to="/register" className={styles.btnConsult}>
                    Register Now
                  </Link>
                </div>
              </div>
            </div>
            <div className={styles.image}>
              <img src="/images/landing/hero.webp" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.searchWidiget}>
        <div className={[styles.widgetWrapper, "container"].join(" ")}>
          <div>
            <div className={styles.quickLinks}>
              <a
                href={`/search-program?${new URLSearchParams({
                  levelOfStudy: JSON.stringify(["Undergraduate"]),
                }).toString()}`}
              >
                Undergraduate
              </a>
              <a
                href={`/search-program?${new URLSearchParams({
                  levelOfStudy: JSON.stringify(["Postgraduate"]),
                }).toString()}`}
              >
                Postgraduate
              </a>
              <a
                href={`/search-program?${new URLSearchParams({
                  levelOfStudy: JSON.stringify(["High Schools"]),
                }).toString()}`}
              >
                High Schools
              </a>
              <a
                href={`/search-program?${new URLSearchParams({
                  levelOfStudy: JSON.stringify(["Universities"]),
                }).toString()}`}
              >
                Universities
              </a>
              <a
                href={`/search-program?${new URLSearchParams({
                  levelOfStudy: JSON.stringify(["Vocational Training"]),
                }).toString()}`}
              >
                Vocational Training
              </a>
            </div>
            <div>
              <Widget
                setProgram={setProgram}
                setLocation={setLocation}
                setLevelOfStudy={setLevelOfStudy}
                onClick={handleSearchPrograms}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.siteContent} id="why_campus_camer">
        <div className={[styles.wrapper, "container"].join(" ")}>
          {/* Contents */}
          <div className={styles.content}>
            <div className={styles.whyUs}>
              <h2>Why Choose CampusCamer?</h2>
              <div className={styles.whyUsData}>
                <div className={styles.whyUscontent}>
                  {/* <h3>
                    <i>“Because Cameroon is the place to study”</i>
                  </h3> */}
                  <p>
                    <strong>Quality Education</strong>: Access top-notch
                    programmes and institutions across Cameroon.
                  </p>
                  <p>
                    <strong>Diverse Options</strong>: Find the perfect fit for
                    your academic goals, from high school to postgraduate
                    studies.
                  </p>
                  <p>
                    <strong>Local Opportunities</strong>: Stay close to home
                    while pursuing your educational aspirations.
                  </p>
                  <p>
                    <strong>Personalized Guidance</strong>: Receive support and
                    advice tailored to your needs.
                  </p>
                </div>
                <div className={styles.image}>
                  <img src="/images/landing/why_us.jpeg" />
                </div>
              </div>
            </div>

            <div className={styles.programsNearYou} id="programs_near_you">
              <h2>Amazing Programs Near You</h2>
              <p>
                Let's match you to the perfect school and program! Explore
                diverse options across six (06) vibrant regions and start your
                exciting educational journey today.
              </p>
              <div className={styles.viewMap}>
                <Link to="/search-program">View More</Link>
              </div>
              <div>
                <Carousel />
              </div>
            </div>

            <div className={styles.featuredInstitutions} id="featured_programs">
              <h2>Featured Institutions</h2>
              <div className={styles.institutions}>
                <FeaturedInstitutions />
              </div>
              <div className={styles.moreSchools}>
                <a href="/search-program">
                  DISCOVER MORE SCHOOLS <FaChevronRight size={20} />
                </a>
              </div>
            </div>

            <div className={styles.registrationSteps} id="registration_steps">
              <div className={styles.wrapper}>
                <div className={styles.title}>
                  <h4>5 Steps to Studying in Cameroon</h4>
                </div>
                <div className={styles.accordions}>
                  <Accordion data={data} />
                </div>
              </div>
              <div className={styles.moreSchools}>
                <a href="search-program">
                  READY TO BEGIN ? SEARCH PROGRAMS NOW{" "}
                  <FaChevronRight size={20} />
                </a>
              </div>
            </div>

            {/* <div className={styles.testimonial} id="testimonial">
              <h2>What Satisfied Students Say</h2>
              <div>
                <Testimonial />
              </div>
            </div> */}
          </div>

          {/* Table Of Contents */}
          <div className={styles.tableOfContent}>
            <h2>Jump To</h2>
            <div className={styles.links}>
              <a
                href="#why_campus_camer"
                className={
                  activeSection === "why_campus_camer" ? styles.active : ""
                }
              >
                Why Choose Campus Camer
              </a>
              <a
                href="#programs_near_you"
                className={
                  activeSection === "programs_near_you" ? styles.active : ""
                }
              >
                Amazing Programs Near You
              </a>
              <a
                href="#featured_programs"
                className={
                  activeSection === "featured_programs" ? styles.active : ""
                }
              >
                Featured Institution
              </a>
              <a
                href="#registration_steps"
                className={
                  activeSection === "registration_steps" ? styles.active : ""
                }
              >
                5 Excitiing Steps To Studying In Cameroon
              </a>
              {/* <a
                href="#registration_steps"
                className={
                  activeSection === "registration_steps" ? styles.active : ""
                }
              >
                How To Apply
              </a> */}
              {/* <a
                href="#testimonial"
                className={activeSection === "testimonial" ? styles.active : ""}
              >
                What Our Satisfied Students Say
              </a> */}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.callToAction}>
        <h2>Ready To Start Your Journey?</h2>
        <p>
          Explore exceptional educational opportunities right here in Cameroon.
          Join CampusCamer today and take the first step towards your dream
          education in Cameroon.
        </p>
        <div className={styles.btns}>
          <a href="/register">
            SIGN UP NOW <FaChevronRight size={20} />
          </a>
        </div>
      </div>

      <div className={[styles.footer, "container"].join(" ")}>
        <div className={styles.details}>
          <div className={styles.brand}>
            <h2>
              Campus<span>Camer</span>
            </h2>
            <p>**Empowering Your Educational Journey in Cameroon**</p>

            <div className={styles.icons}>
              <a href="#" className={styles.icon}>
                <FaFacebookF />
              </a>
              <a href="#" className={styles.icon}>
                <BsTwitterX />
              </a>
            </div>
          </div>
          <div className={styles.quickLink}>
            <h3>Quick Links</h3>
            <div>
              <a href="#why_campus_camer">Why choose campusCamer?</a>
            </div>
            <div>
              <a href="#programs_near_you">Amazing programs near you</a>
            </div>
            <div>
              <a href="#featured_programs">Featured institutions</a>
            </div>
            <div>
              <a href="#registration_steps">5 steps to studying in Cameroom</a>
            </div>
            <div>
              <a href="#registration_steps">How to Apply</a>
            </div>
          </div>
          <div className={styles.contacts}>
            <h3>Contact Us</h3>
            <div>
              <p>123 CampusCamer Lane, Douala, Cameroon</p>
            </div>
            <div>
              <p>support@campuscamer.com</p>
            </div>
          </div>
        </div>
        <div className={styles.logos}>
          <div>
            <h2>Powered By</h2>
            <img src="/logos/aeco.png" />
          </div>
          {/* <div>
            <img src="/logos/aecoeducation.png" />
          </div>
          <div>
            <img src="/logos/opuvia.png" />
          </div> */}
        </div>
      </div>

      <div className={styles.footerbottom}>
        <p>
          <a href="#">Terms Of Service</a>
          <span>
            <a href="#">Privacy Policy</a>
          </span>
          &copy; CampusCamer. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Landing;
