// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_accordion__Z30Qt {
    width: 100%;
    border-radius: 4px;
}

.styles_accordionItem__wVtJn {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}

.styles_accordionItem__wVtJn:last-child {
    border-bottom: none;
}

.styles_accordionTitle__zfISU {
    background-color: #DDDDDD;
    padding: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.styles_accordionTitle__zfISU .styles_titleDiv__-XSOP {
    display: flex;
    align-items: center;
}

.styles_accordionTitle__zfISU span {
    margin-right: 10px;
    color: #1D1D1D;
    font-size: 26px;
}

.styles_accordionTitle__zfISU .styles_title__nfn1f {
    font-weight: bold;
    font-size: 18px;
}

.styles_accordionBody__HE-zC {
    padding: 15px;
    background-color: #fff;
    border-top: 1px solid #ccc;
}

.styles_accordionBody__HE-zC p {
    color: #1D1D1D;
}`, "",{"version":3,"sources":["webpack://./src/components/landing/accordion/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,0BAA0B;AAC9B;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".accordion {\n    width: 100%;\n    border-radius: 4px;\n}\n\n.accordionItem {\n    border-bottom: 1px solid #ccc;\n    margin-bottom: 10px;\n}\n\n.accordionItem:last-child {\n    border-bottom: none;\n}\n\n.accordionTitle {\n    background-color: #DDDDDD;\n    padding: 15px;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n}\n\n.accordionTitle .titleDiv {\n    display: flex;\n    align-items: center;\n}\n\n.accordionTitle span {\n    margin-right: 10px;\n    color: #1D1D1D;\n    font-size: 26px;\n}\n\n.accordionTitle .title {\n    font-weight: bold;\n    font-size: 18px;\n}\n\n.accordionBody {\n    padding: 15px;\n    background-color: #fff;\n    border-top: 1px solid #ccc;\n}\n\n.accordionBody p {\n    color: #1D1D1D;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion": `styles_accordion__Z30Qt`,
	"accordionItem": `styles_accordionItem__wVtJn`,
	"accordionTitle": `styles_accordionTitle__zfISU`,
	"titleDiv": `styles_titleDiv__-XSOP`,
	"title": `styles_title__nfn1f`,
	"accordionBody": `styles_accordionBody__HE-zC`
};
export default ___CSS_LOADER_EXPORT___;
