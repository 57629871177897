import React, { useEffect, useState } from "react";
import { updateProgram } from "../../../redux/reducers/program";
import { getUniversities } from "../../../redux/reducers/university";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { ColorRing } from "react-loader-spinner";
import Select from "react-select";

const Settings = ({ program }) => {
  const [name, setName] = useState("");
  const [levelOfStudy, setLevelOfStudy] = useState("Undergraduate");
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");
  const [applicationDeadline, setApplicationDeadline] = useState(null);
  const [location, setLocation] = useState("On campus");
  const [admissionRequirements, setAdmissionRequirements] = useState([]);
  const [conditionsForAcceptance, setConditionsForAcceptance] = useState([]);

  const [awardingBody, setAwardingBody] = useState("");
  const [tuitionFee, setTuitionFee] = useState("");
  const [initialDeposit, setInitialDeposit] = useState("");
  const [otherFees, setOtherFees] = useState("");
  const [teachingMode, setTeachingMode] = useState("");
  const [teachingMethods, setTeachingMethods] = useState("");
  const [mediumOfInstruction, setMediumOfInstruction] = useState("");
  const [academicYear, setAcademicYear] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [loading, setLoading] = useState(false);

  const [universities, setUniversities] = useState([]);
  const [university, setUniversity] = useState(null);

  const dispatch = useDispatch();

  const handleUpdateProgram = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = {
        id: program._id,
        name: name,
        levelOfStudy: levelOfStudy,
        description: description,
        duration: duration,
        applicationDeadline: applicationDeadline,
        location: location,
        admissionRequirements: admissionRequirements,
        conditionsForAcceptance: conditionsForAcceptance,
        //
        awardingBody,
        tuitionFee,
        initialDeposit,
        otherFees,
        teachingMode,
        teachingMethods,
        academicYear,
        startDate,
        endDate,
      };
      await dispatch(updateProgram(data)).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          setLoading(false);
          return;
        } else {
          toast.success(res.payload.message);
          setLoading(false);
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setName(program.name || "");
    setLevelOfStudy(program.levelOfStudy || "Undergraduate");
    setDescription(program.description || "");
    setDuration(program.duration || "");
    setApplicationDeadline(program.applicationDeadline || null);
    setLocation(program.location || "On campus");
    setAdmissionRequirements(program.admissionRequirements || []);
    setConditionsForAcceptance(program.conditionsForAcceptance || []);
    setUniversity(program.universityId || "");
    //
    setAwardingBody(program.awardingBody || "");
    setTuitionFee(program.tuitionFee || "");
    setInitialDeposit(program.initialDeposit || "");
    setOtherFees(program.otherFees || "");
    setTeachingMode(program.teachingMode || "");
    setTeachingMethods(program.teachingMethods || "");
    setMediumOfInstruction(program.mediumOfInstruction || "");
    setAcademicYear(program.academicYear || "");
    setStartDate(program.startDate || null);
    setEndDate(program.endDate || null);
  }, [program]);

  const handleAddRequirement = () => {
    setAdmissionRequirements([
      ...admissionRequirements,
      { title: "", value: "" },
    ]);
  };

  const handleAddCondition = () => {
    setConditionsForAcceptance([
      ...conditionsForAcceptance,
      { title: "", value: "" },
    ]);
  };

  const handleRequirementChange = (index, field, value) => {
    const newRequirements = [...admissionRequirements];
    newRequirements[index][field] = value;
    setAdmissionRequirements(newRequirements);
  };

  const handleConditionChange = (index, field, value) => {
    const newConditions = [...conditionsForAcceptance];
    newConditions[index][field] = value;
    setConditionsForAcceptance(newConditions);
  };

  const handleDeleteRequirement = (index) => {
    const newRequirements = admissionRequirements.filter((_, i) => i !== index);
    setAdmissionRequirements(newRequirements);
  };

  const handleDeleteCondition = (index) => {
    const newConditions = conditionsForAcceptance.filter((_, i) => i !== index);
    setConditionsForAcceptance(newConditions);
  };

  // Get universities
  const handleGetUniversities = async (e) => {
    try {
      setLoading(true);
      await dispatch(getUniversities()).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          setLoading(false);
          return;
        } else {
          const structured = res.payload.map((item) => {
            return { label: item.name, value: item._id };
          });
          setUniversities(structured);
          setLoading(false);
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleGetUniversities();
  }, []);

  return (
    <div id="settings" className="tab-pane fade">
      <div className="pt-3">
        <div className="settings-form">
          <h5 className="text-primary">Program Details</h5>
          <form>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label">Program Name</label>
                <input
                  type="text"
                  placeholder="Program Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              {/* <div className="mb-3 col-md-6">
                <label className="form-label">Level of Study</label>
                <select
                  className="form-control"
                  value={levelOfStudy}
                  onChange={(e) => setLevelOfStudy(e.target.value)}
                >
                  <option value="Undergraduate">Undergraduate</option>
                  <option value="Graduate">Graduate</option>
                  <option value="Diploma">Diploma</option>
                  <option value="Certificate">Certificate</option>
                  <option value="Other">Other</option>
                </select>
              </div> */}
              <div className="mb-3 col-md-6">
                <label className="form-label">Level of Study</label>
                <input
                  type="text"
                  placeholder="Location"
                  className="form-control"
                  value={levelOfStudy}
                  onChange={(e) => setLevelOfStudy(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label">Duration</label>
                <input
                  type="text"
                  placeholder="Duration"
                  className="form-control"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label">Application Deadline</label>
                <input
                  type="date"
                  className="form-control"
                  value={
                    new Date(applicationDeadline).toISOString().split("T")[0]
                  }
                  onChange={(e) => setApplicationDeadline(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              {/* <div className="mb-3 col-md-6">
                <label className="form-label">Location</label>
                <select
                  className="form-control"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                >
                  <option value="On campus">On campus</option>
                  <option value="Online">Online</option>
                  <option value="Hybrid">Hybrid</option>
                  <option value="Other">Other</option>
                </select>
              </div> */}
              <div className="mb-3 col-md-6">
                <label className="form-label">Study Location</label>
                <input
                  type="text"
                  placeholder="Location"
                  className="form-control"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label">University</label>
                <Select
                  options={universities}
                  onChange={(selected) => setUniversity(selected.value)}
                  isSearchable
                  placeholder="Select a university..."
                />
              </div>
            </div>

            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">
                  Awarding Body<span class="required">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Awarding Body"
                  className="form-control"
                  value={awardingBody}
                  onChange={(e) => setAwardingBody(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">
                  Tuition Fee<span class="required">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Tuition Fee"
                  className="form-control"
                  value={tuitionFee}
                  onChange={(e) => setTuitionFee(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">
                  Initial Deposite<span class="required">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Initial Deposite"
                  className="form-control"
                  value={initialDeposit}
                  onChange={(e) => setInitialDeposit(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">
                  Other Fees<span class="required">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Other Fees"
                  className="form-control"
                  value={otherFees}
                  onChange={(e) => setOtherFees(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">
                  Teaching Mode<span class="required">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Teaching Mode"
                  className="form-control"
                  value={teachingMode}
                  onChange={(e) => setTeachingMode(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">
                  Teaching Methods<span class="required">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Teaching Methods"
                  className="form-control"
                  value={teachingMethods}
                  onChange={(e) => setTeachingMethods(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">
                  Program Start Date<span class="required">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  value={new Date(startDate).toISOString().split("T")[0]}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">
                  Program End Date<span class="required">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  value={new Date(endDate).toISOString().split("T")[0]}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">
                  Academic Year<span class="required">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Academic Year"
                  className="form-control"
                  value={academicYear}
                  onChange={(e) => setAcademicYear(e.target.value)}
                />
              </div>

              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">
                  Medium Of Instruction<span class="required">*</span>
                </label>
                <select
                  className="form-control"
                  value={mediumOfInstruction}
                  onChange={(e) => setMediumOfInstruction(e.target.value)}
                >
                  <option value="">Select Medium</option>
                  <option value="ENGLISH">English</option>
                  <option value="FRENCH">French</option>
                  <option value="BILINGGUAL">Bilingual</option>
                </select>
              </div>
            </div>
            <div className="mb-3 col-md-12">
              <label className="form-label text-primary">Description</label>
              <textarea
                placeholder="Description"
                className="form-control"
                rows="6"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <h5 className="text-primary mt-5">Admission Requirements</h5>
            {admissionRequirements.map((req, index) => (
              <div key={index} className="row mb-3">
                <div className="col-md-5">
                  <label className="form-label">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    value={req.title}
                    onChange={(e) =>
                      handleRequirementChange(index, "title", e.target.value)
                    }
                  />
                </div>
                <div className="col-md-5">
                  <label className="form-label">Value</label>
                  <input
                    type="text"
                    className="form-control"
                    value={req.value}
                    onChange={(e) =>
                      handleRequirementChange(index, "value", e.target.value)
                    }
                  />
                </div>
                <div className="col-md-2 d-flex align-items-end">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleDeleteRequirement(index)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
            <button
              type="button"
              className="btn btn-secondary mb-3"
              onClick={handleAddRequirement}
            >
              Add Requirement
            </button>
            <h5 className="text-primary mt-5">Conditions for Acceptance</h5>
            {conditionsForAcceptance.map((cond, index) => (
              <div key={index} className="row mb-3">
                <div className="col-md-5">
                  <label className="form-label">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    value={cond.title}
                    onChange={(e) =>
                      handleConditionChange(index, "title", e.target.value)
                    }
                  />
                </div>
                <div className="col-md-5">
                  <label className="form-label">Value</label>
                  <input
                    type="text"
                    className="form-control"
                    value={cond.value}
                    onChange={(e) =>
                      handleConditionChange(index, "value", e.target.value)
                    }
                  />
                </div>
                <div className="col-md-2 d-flex align-items-end">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleDeleteCondition(index)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
            <button
              type="button"
              className="btn btn-secondary mb-3"
              onClick={handleAddCondition}
            >
              Add Condition
            </button>
            <div>
              <button
                className="btn btn-primary"
                type="submit"
                onClick={handleUpdateProgram}
                disabled={loading}
              >
                {loading ? (
                  <ColorRing
                    visible={true}
                    height="22"
                    width="22"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Settings;
