import React from "react";
import About from "../about/About";
import University from "../university/University";
import Student from "../student/Student";
import Documents from "../documents/Documents";
import Settings from "../settings/Settings";

const tabList = [
  {
    title: "Program Info",
    href: "about",
  },
  {
    title: "University Info",
    href: "university",
  },
  {
    title: "Student Info",
    href: "student",
  },
  {
    title: "Documents",
    href: "documents",
  },
  {
    title: "Settings",
    href: "settings",
  },
];
const Tab = ({ program, university, student, application }) => {
  return (
    <div class="col-xl-12">
      <div class="card h-auto">
        <div class="card-body">
          <div class="profile-tab">
            <div class="custom-tab-1">
              <ul class="nav nav-tabs">
                {tabList.map((item, index) => {
                  return (
                    <li class="nav-item" key={index}>
                      <a
                        href={`#${item.href}`}
                        data-bs-toggle="tab"
                        class={`nav-link ${index === 0 && "active show"}`}
                      >
                        {item.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
              <div class="tab-content">
                <About program={program} />
                <University university={university} />
                <Student student={student} />
                <Documents application={application} />
                <Settings
                  application={application}
                  student={student}
                  program={program}
                  university={university}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tab;
