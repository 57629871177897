import React from "react";

const Payments = ({ university }) => {
  return (
    <div id="payments" class="tab-pane fade">
      <div class="pt-4 profile-personal-info">
        <h5 class="text-primary mb-4">Bank Information</h5>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Name <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{university.paymentDetails.bankTransfer.nameOfBank}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Account Number <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{university.paymentDetails.bankTransfer.accountNumber}</span>
          </div>
        </div>
      </div>

      <div class="pt-4 profile-personal-info">
        <h5 class="text-primary mb-4">Mobile Money Information</h5>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Account Number <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{university.paymentDetails.mobileMoney}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;
