import React, { useContext } from "react";
import About from "../about/About";
import Contacts from "../contacts/Contacts";
import Payments from "../payment/Payments";
import Settings from "../settings/Settings";
import Documents from "../documents/Documents";
import { AuthContext } from "../../../context/AuthContext";

const Tab = ({ university }) => {
  const { role } = useContext(AuthContext);
  const tabList = [
    {
      title: "About",
      href: "about",
    },
    {
      title: "Contact Info",
      href: "contacts",
    },
    {
      title: "Payment  Info",
      href: "payments",
    },
    {
      title: "Documents",
      href: "documents",
    },
    role !== "STUDENT" && {
      title: "Settings",
      href: "settings",
    },
  ].filter(Boolean);

  return (
    <div class="col-xl-12">
      <div class="card h-auto">
        <div class="card-body">
          <div class="profile-tab">
            <div class="custom-tab-1">
              <ul class="nav nav-tabs">
                {tabList.map((item, index) => {
                  return (
                    <li class="nav-item" key={index}>
                      <a
                        href={`#${item.href}`}
                        data-bs-toggle="tab"
                        class={`nav-link ${index === 0 && "active show"}`}
                      >
                        {item.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
              <div class="tab-content">
                <About university={university} />
                <Contacts university={university} />
                <Payments university={university} />
                <Documents university={university} />
                <Settings university={university} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tab;
