import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import styles from "./programSearch.module.css";

import Widget from "../../components/programSearch/widget/Widget";
import FilterSidebar from "../../components/programSearch/filter/Filter";
import ProgramItem from "../../components/programSearch/programItem/ProgramItem";

import Loader from "../../components/Loader/Loader";
import {
  getProgramsWithUniversity,
  advancedSearch,
} from "../../redux/reducers/program";
import { getCities } from "../../redux/reducers/university";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

const ProgramSearch = () => {
  const navigate = useNavigate();
  // Query parameters
  const query = useLocation();
  const queryParams = new URLSearchParams(query.search);
  const queryProgram = JSON.parse(queryParams.get("name") || "[]");
  const queryLocation = JSON.parse(queryParams.get("location") || "[]");
  const queryStudyLevel = JSON.parse(queryParams.get("levelOfStudy") || "[]");
  const queryAcademicYear = JSON.parse(queryParams.get("academicYear") || "[]");
  const queryTuitionFee = JSON.parse(queryParams.get("tuitionFee") || "[]");
  const queryDuration = JSON.parse(queryParams.get("duration") || "[]");

  // state variables
  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [listPrograms, setListPrograms] = useState([]);
  const [uniqueProgramNames, setUniqueProgramNamesArray] = useState([]);
  const [citiesArray, setCitiesArray] = useState([]);
  const dispatch = useDispatch();

  // Search Variables
  const [program, setProgram] = useState([]);
  const [levelOfStudy, setLevelOfStudy] = useState([]);
  const [location, setLocation] = useState([]);
  const [academicYear, setAcademicYear] = useState([]);
  const [tuitionFee, setTuitionFee] = useState([]);
  const [duration, setDuration] = useState([]);

  const handleGetPrograms = async (e) => {
    try {
      setLoading(true);
      await dispatch(getProgramsWithUniversity()).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          setLoading(false);
          return;
        } else {
          setPrograms(res.payload);
          setListPrograms(res.payload);
          setLoading(false);
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleGetCities = async () => {
    try {
      await dispatch(getCities()).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          return;
        } else {
          const uniqueCities = res.payload.map((item) => item.city);
          setCitiesArray(uniqueCities);
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSearchPrograms = async () => {
    try {
      const params = {
        name: queryProgram,
        location: queryLocation,
        levelOfStudy: queryStudyLevel,
        academicYear: queryAcademicYear,
        tuitionFee: queryTuitionFee,
        duration: queryDuration,
      };
      setLoading(true);
      await dispatch(advancedSearch(params)).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          // toast.error(res.payload);
          setLoading(false);
          return;
        } else {
          console.log(res.payload);
          setPrograms([
            ...res.payload.programs,
            ...res.payload.relatedPrograms,
          ]);
          setLoading(false);
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const searchProgram = () => {
    const params = {
      name: JSON.stringify(program),
      location: JSON.stringify(location),
      levelOfStudy: JSON.stringify(levelOfStudy),
      academicYear: JSON.stringify(academicYear),
      tuitionFee: JSON.stringify(tuitionFee),
      duration: JSON.stringify(duration),
    };
    navigate(`/search-program?${new URLSearchParams(params).toString()}`);
  };

  useEffect(() => {
    handleSearchPrograms();
  }, [query.search]);

  useEffect(() => {
    handleGetPrograms();
    handleGetCities();
  }, []);

  useEffect(() => {
    const programNames = listPrograms.map((program) => program.name);
    const uniqueProgramNamesSet = new Set(programNames);
    const uniqueProgramNamesArray = Array.from(uniqueProgramNamesSet);
    setUniqueProgramNamesArray(uniqueProgramNamesArray);
  }, [programs]);

  const programLevels = [
    "Undergraduate Programs",
    "Postgraduate Programs",
    "Doctoral Programs",
    "Professional Programs",
    "Diplomas and Certificates",
    "Technical and Vocational Education",
  ];

  const tuitionFees = [
    "0 XAF - 100,000 XAF",
    "100,000 XAF - 200,000 XAF",
    "200,000 XAF - 300,000 XAF",
    "300,000 XAF - 400,000 XAF",
    "Over 400,000 XAF",
  ];

  const intakes = [
    `${new Date().getFullYear()}`,
    `${new Date().getFullYear() + 1}`,
  ];

  const durations = [
    "1 year or less",
    "1 - 2 years",
    "2 years",
    "2 - 3 years",
    "3 years",
    "3 - 4 years",
    "4 years",
    "4 years and More",
  ];

  return (
    <>
      <Navbar />
      <div className="container">
        <div className={styles.searchSection}>
          <h1>Find Your Academic School</h1>
          <div className={styles.searchWidget}>
            <Widget
              levels={programLevels}
              programs={uniqueProgramNames}
              setProgram={setProgram}
              setLocation={setLocation}
              setLevelOfStudy={setLevelOfStudy}
              onClick={searchProgram}
            />
          </div>
        </div>

        <div className={styles.programList}>
          <div className={styles.filter}>
            <FilterSidebar
              citiesArray={citiesArray}
              programLevels={programLevels}
              tuitionFees={tuitionFees}
              intakes={intakes}
              durations={durations}
              // Initial values
              program={program}
              location={location}
              levelOfStudy={levelOfStudy}
              academicYear={academicYear}
              tuitionFee={tuitionFee}
              duration={duration}
              // set functions
              setProgram={setProgram}
              setLocation={setLocation}
              setLevelOfStudy={setLevelOfStudy}
              setAcademicYear={setAcademicYear}
              setTuitionFee={setTuitionFee}
              setDuration={setDuration}
            />
          </div>
          {loading ? (
            <div className={styles.loader}>
              <Loader />
            </div>
          ) : (
            <>
              <div className={styles.programs}>
                {programs.length > 0 ? (
                  programs.map((program, index) => {
                    return <ProgramItem key={index} program={program} />;
                  })
                ) : (
                  <div className={styles.noProgramsFound}>
                    No Programs Found
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProgramSearch;
