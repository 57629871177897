import React from "react";
import { ColorRing } from "react-loader-spinner";

const Loader = () => {
  return (
    // <div id="preloader">
    //   <div>
    //     <div className="loader">
    //       <div className="dots">
    //         <div className="dot mainDot"></div>
    //         <div className="dot"></div>
    //         <div className="dot"></div>
    //         <div className="dot"></div>
    //         <div className="dot"></div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <ColorRing
      visible={true}
      height="60"
      width="60"
      ariaLabel="color-ring-loading"
      wrapperStyle={{}}
      wrapperClass="color-ring-wrapper"
      colors={["#000", "#000", "#000", "#000", "#000"]}
    />
  );
};

export default Loader;
