import React from "react";

const ComposeEmail = () => {
  return (
    <div className="col-lg-9 col-xl-10 col-xxl-9">
      <div className="email-right-box ms-0 mt-3 ">
        <div className="compose-wrapper " id="compose-content">
          <div className="compose-content">
            <form action="#">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control bg-transparent"
                  placeholder=" To:"
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control bg-transparent"
                  placeholder=" Subject:"
                />
              </div>
              <div className="mb-3">
                <textarea
                  id="email-compose-editor"
                  className="textarea_editor form-control bg-transparent"
                  rows="5"
                  placeholder="Enter text ..."
                ></textarea>
              </div>
            </form>
            {/* <h5 className="my-3">
              <i className="fa fa-paperclip me-2"></i> Attatchment
            </h5>
            <form action="#" className="dropzone">
              <div className="fallback">
                <input name="file" type="file" multiple />
              </div>
            </form> */}
          </div>
          <div className="text-start mt-4 mb-3">
            <button className="btn btn-primary btn-sl-sm me-2" type="button">
              <span className="me-2">
                <i className="fa fa-paper-plane"></i>
              </span>
              Send
            </button>
            <button className="btn btn-danger light btn-sl-sm" type="button">
              <span className="me-2">
                <i className="fa fa-times"></i>
              </span>
              Discard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComposeEmail;
