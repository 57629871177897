import React, { useState, useEffect } from "react";
import { updateApplication } from "../../../redux/reducers/application";
import uploadToS3 from "../../../utils/awsS3Upload";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { ColorRing } from "react-loader-spinner";

const Documents = ({ application }) => {
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [savedFiles, setSavedFiles] = useState([]);

  const [files, setFiles] = useState([]);
  const [titles, setTitles] = useState({});
  const [previews, setPreviews] = useState({});

  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newPreviews = {};

    selectedFiles.forEach((file, index) => {
      newPreviews[index] = URL.createObjectURL(file);
    });

    setFiles(selectedFiles);
    setPreviews(newPreviews);
  };

  const handleTitleChange = (index, title) => {
    setTitles({
      ...titles,
      [index]: title,
    });
  };

  const handleUpdateApplication = async (e) => {
    e.preventDefault();
    try {
      // Validate titles
      for (let i = 0; i < files.length; i++) {
        if (!titles[i] || titles[i].trim() === "") {
          toast.error("please enter a title for all files.");
          return;
        }
      }

      setLoading(true);
      let uploadedFiles = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const title = titles[i];

        try {
          const data = await uploadToS3(file, "application-docs", file?.name);
          uploadedFiles.push({ title, value: JSON.stringify(data) });
          setUploading(false);
        } catch (err) {
          toast.error("error uploading file: ");
          setUploading(false);
          return;
        }
      }

      const data = {
        id: application._id,
        documents: [...savedFiles, ...uploadedFiles],
      };
      await dispatch(updateApplication(data)).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          setLoading(false);
          return;
        } else {
          toast.success(res.payload.message);
          setLoading(false);
          window.location.reload();
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    setSavedFiles(application.documents);
  }, [application]);

  const removeDoc = (id) => {
    const docs = savedFiles.filter((items) => items._id !== id);
    setSavedFiles(docs);
  };

  return (
    <div id="documents" class="tab-pane fade">
      <div class="pt-4 profile-personal-info">
        <h5 class="text-primary mb-4">Documents</h5>
        {savedFiles.length > 0
          ? savedFiles.map((item, index) => {
              return (
                <div class="row mb-2" key={index}>
                  <div class="col-sm-3 col-5">
                    <h5 class="f-w-500">
                      {item.title} <span class="pull-end">:</span>
                    </h5>
                  </div>
                  <div class="col-sm-9 col-7 mb-4">
                    <span>
                      <a
                        href={JSON.parse(item.value)?.Location}
                        target="_blank"
                      >
                        {JSON.parse(item.value)?.Location}
                      </a>
                    </span>
                    <div>
                      <img
                        src={JSON.parse(item.value)?.Location}
                        alt="Preview"
                        style={{
                          maxWidth: "400px",
                          height: "auto",
                          marginTop: 10,
                        }}
                      />
                    </div>
                    <div>
                      <button
                        className="btn text-danger mt-2"
                        onClick={() => removeDoc(item._id)}
                      >
                        remove
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
          : "No Documents Added"}

        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="form-control mb-5"
        />
        {files.map((file, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              marginTop: 20,
            }}
          >
            <h4>{index + 1}.</h4>
            <div className="w-100 mx-2">
              <div>
                {file.type.startsWith("image/") ? (
                  <img
                    src={previews[index]}
                    alt={`Preview ${index}`}
                    width="100"
                    className="mt-2"
                  />
                ) : (
                  <a
                    href={previews[index]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {file.name}
                  </a>
                )}
              </div>
              <input
                type="text"
                className="form-control mt-2"
                placeholder={`Title for ${file.name}`}
                onChange={(e) => handleTitleChange(index, e.target.value)}
              />
            </div>
          </div>
        ))}
        <div className="row mt-4">
          <div className="my-2">
            {uploading ? "Uploading documents ...." : ""}
          </div>
          <div className="d-flex justify-content-between mb-2">
            {/* <div></div> */}
            <button
              className="btn btn-primary"
              type="submit"
              onClick={handleUpdateApplication}
              disabled={loading}
            >
              {loading ? (
                <ColorRing
                  visible={true}
                  height="22"
                  width="22"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
