import React from "react";
import moment from "moment";
import { IoChevronForward } from "react-icons/io5";

const StudentInfo = ({ student }) => {
  return (
    <div id="student" class="tab-pane fade">
      <div class="pt-4 profile-personal-info">
        <h5 class="text-primary mb-4">More Information</h5>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Name <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{student.fullName}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              AMS ID <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{student.studentDetails.amsId}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              O/L ID <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>
              {student.studentDetails.ordinaryLevelIdentificationNumber}
            </span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Email <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{student.emailAddress}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Phone Number <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{student.phoneNumber}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Date of Birth <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>
              {moment(student.studentDetails.dateOfBirth).format("DD-MM-YYYY")}
            </span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Location <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{student.studentDetails.address}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Gender <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{student.studentDetails.gender}</span>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <a
          href={`/students-details?=${student?._id}`}
          className="text-secondary"
        >
          More Info <IoChevronForward />
        </a>
      </div>
    </div>
  );
};

export default StudentInfo;
