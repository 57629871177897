import React from "react";
import About from "./about/About";
import University from "./university/University";
import Requirements from "./requirements/Requirements";
import Conditions from "./conditions/Conditions";

const tabList = [
  {
    title: "More Info",
    href: "about",
  },
  {
    title: "University Info",
    href: "university",
  },
  {
    title: "Requirements",
    href: "requirements",
  },
  {
    title: "Conditions",
    href: "conditions",
  },
];

const Tab = ({ program, university }) => {
  return (
    <div class="col-xl-12">
      <div class="card h-auto">
        <div class="card-body">
          <div class="profile-tab">
            <div class="custom-tab-1">
              <ul class="nav nav-tabs">
                {tabList.map((item, index) => {
                  return (
                    <li class="nav-item" key={index}>
                      <a
                        href={`#${item.href}`}
                        data-bs-toggle="tab"
                        class={`nav-link ${index === 0 && "active show"}`}
                      >
                        {item.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
              <div class="tab-content">
                <About program={program} />
                <University university={university} />
                <Requirements program={program} />
                <Conditions program={program} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tab;
