import React from "react";
import { IoChevronForward } from "react-icons/io5";

const University = ({ university }) => {
  return (
    <div id="university" class="tab-pane fade">
      <div class="pt-4 profile-personal-info">
        <h5 class="text-primary mb-4">More Information</h5>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Name <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{university?.name}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              City <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{university?.location.city}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              State/Province <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{university?.location.stateOrProvince}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Country <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{university?.location.country}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Description <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{university?.description}</span>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <a
          href={`/university-details?=${university?._id}`}
          className="text-secondary"
        >
          More Info <IoChevronForward />
        </a>
      </div>
    </div>
  );
};

export default University;
