// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_conditional_offer_letter__J2N3y,
.style_final_offer_letter__w5kZr {
    /* height: 1250px; */
    width: 1000px;
    padding: 0 80px 40px 80px;
    margin: auto;
}

.style_conditional_offer_letter__J2N3y h3,
.style_final_offer_letter__w5kZr h3 {
    text-align: center;
    margin-bottom: 10px;
}

.style_conditional_offer_letter__J2N3y h4 {
    margin-top: 40px;
}

.style_conditional_offer_letter__J2N3y p,
.style_final_offer_letter__w5kZr p {
    font-size: 16px;
    color: black;
}

.style_conditional_offer_letter__J2N3y table th td {
    font-weight: bold;
}

.style_conditional_offer_letter__J2N3y table tr {
    width: 100%;
}

.style_conditional_offer_letter__J2N3y table tr td {
    width: 397px;
    color: black;
    border: 1px solid black;
    padding: 5px;
}

.style_conditional_offer_letter__J2N3y .style_logoDiv__Nx3HE {
    text-align: center;
    margin-bottom: 30px;
}

.style_conditional_offer_letter__J2N3y .style_logo__HR\\+EV {
    width: 100px;
    border-radius: 50%;
}

.style_signatory__cWiVC {
    margin: 30px 0 0 0 !important;
    text-align: left !important;
}

.style_final_offer_letter__w5kZr .style_letterHeadDiv__D5PPf {
    text-align: center;
    margin-bottom: 30px;
}

.style_final_offer_letter__w5kZr .style_footer__sAA0o {
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/letters/style.module.css"],"names":[],"mappings":"AAAA;;IAEI,oBAAoB;IACpB,aAAa;IACb,yBAAyB;IACzB,YAAY;AAChB;;AAEA;;IAEI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;IAEI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".conditional_offer_letter,\n.final_offer_letter {\n    /* height: 1250px; */\n    width: 1000px;\n    padding: 0 80px 40px 80px;\n    margin: auto;\n}\n\n.conditional_offer_letter h3,\n.final_offer_letter h3 {\n    text-align: center;\n    margin-bottom: 10px;\n}\n\n.conditional_offer_letter h4 {\n    margin-top: 40px;\n}\n\n.conditional_offer_letter p,\n.final_offer_letter p {\n    font-size: 16px;\n    color: black;\n}\n\n.conditional_offer_letter table th td {\n    font-weight: bold;\n}\n\n.conditional_offer_letter table tr {\n    width: 100%;\n}\n\n.conditional_offer_letter table tr td {\n    width: 397px;\n    color: black;\n    border: 1px solid black;\n    padding: 5px;\n}\n\n.conditional_offer_letter .logoDiv {\n    text-align: center;\n    margin-bottom: 30px;\n}\n\n.conditional_offer_letter .logo {\n    width: 100px;\n    border-radius: 50%;\n}\n\n.signatory {\n    margin: 30px 0 0 0 !important;\n    text-align: left !important;\n}\n\n.final_offer_letter .letterHeadDiv {\n    text-align: center;\n    margin-bottom: 30px;\n}\n\n.final_offer_letter .footer {\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"conditional_offer_letter": `style_conditional_offer_letter__J2N3y`,
	"final_offer_letter": `style_final_offer_letter__w5kZr`,
	"logoDiv": `style_logoDiv__Nx3HE`,
	"logo": `style_logo__HR+EV`,
	"signatory": `style_signatory__cWiVC`,
	"letterHeadDiv": `style_letterHeadDiv__D5PPf`,
	"footer": `style_footer__sAA0o`
};
export default ___CSS_LOADER_EXPORT___;
