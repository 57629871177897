import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const data = [
  {
    image: "/images/landing/programs_near.png",
    name: "Amina Nchama",
    university: "University of Buea",
    program: "University Education",
    message: `
    "CampusCamer was a game-changer for my education. 
    They helped me navigate the complex application process 
    and find the perfect program at the University of Buea. 
    The support and guidance I received were invaluable, and 
    I couldn't be happier with my choice." `,
  },
  {
    image: "/images/landing/programs_near.png",
    name: "Jean-Claude Mballa",
    university: "Yaoundé Vocational Training Centre",
    program: "Vocational Training",
    message: `
   "Thanks to CampusCamer, I discovered a fantastic vocational 
   training program in Yaoundé. Their personalized approach and 
   extensive resources made it easy to find the right fit for my 
   career goals. I highly recommend CampusCamer to anyone looking 
   for quality education in Cameroon."  `,
  },
  {
    image: "/images/landing/programs_near.png",
    name: "Brenda Nkongho",
    university: "Douala High School",
    program: "High School Education",
    message: `
   "CampusCamer made my Daugther’s transition from high 
   school to university seamless. Their comprehensive 
   database and helpful advisors ensured we found the best 
   undergraduate program in Douala. I am grateful for their 
   support and excited about my academic future."
  `,
  },
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(2);

  useEffect(() => {
    const updateItemsToShow = () => {
      const width = window.innerWidth;
      if (width <= 480) {
        setItemsToShow(1);
      } else {
        setItemsToShow(2);
      }
    };

    updateItemsToShow();
    window.addEventListener("resize", updateItemsToShow);
    return () => window.removeEventListener("resize", updateItemsToShow);
  }, []);

  const next = () => {
    if (currentIndex < data.length - itemsToShow) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className={styles.carousel}>
      <div className={styles.testimonials}>
        <div className={styles.imageContainer}>
          {data
            .slice(currentIndex, currentIndex + itemsToShow)
            .map((item, index) => (
              <div key={index} className={styles.card}>
                <div className={styles.image}>
                  <img src={item.image} />
                </div>

                <div className={styles.details}>
                  <i>
                    <p className={styles.title}>{item.program}</p>
                    <p>{item.message}</p>
                    <hr />
                    <p>{item.name}</p>
                    <p>{item.university}</p>
                  </i>
                </div>
              </div>
            ))}
        </div>
        <div className={styles.btns}>
          <button
            className={styles.button}
            onClick={prev}
            disabled={currentIndex === 0}
          >
            <FaChevronLeft />
          </button>
          <button
            className={styles.button}
            onClick={next}
            disabled={currentIndex >= data.length - itemsToShow}
          >
            <FaChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
