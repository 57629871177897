// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_imageContainer__5pLq4 {
    display: flex;
    overflow: hidden;
    width: 100%;
}

.styles_card__Hqqt6 {
    position: relative;
    width: 300px;
    height: 250px;
    margin: 0 5px;
}

.styles_centerBottom__ddo1o {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    color: white;
}

.styles_centerBottom__ddo1o p {
    font-weight: bold;
    color: white;
}

.styles_bottomRight__Z4o2H {
    position: absolute;
    right: 0;
    bottom: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
}

.styles_bottomRight__Z4o2H p {
    color: #fff;
    width: 120px;
    margin: 0;
    font-weight: bold;
    font-style: italic;
    font-size: 13px;
}

.styles_carousel__QM87f .styles_btns__HHXB6 {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.styles_button__iXXBm {
    background-color: transparent;
    border: 2px solid #2E0830;
    color: #2E0830;
    padding: 2px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

.styles_button__iXXBm:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

@media (max-width: 480px) {
    .styles_card__Hqqt6 {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/landing/carousel/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,YAAY;IACZ,oCAAoC;IACpC,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,SAAS;IACT,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;IAC7B,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB;IACrB,qBAAqB;IACrB,eAAe;IACf,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".imageContainer {\n    display: flex;\n    overflow: hidden;\n    width: 100%;\n}\n\n.card {\n    position: relative;\n    width: 300px;\n    height: 250px;\n    margin: 0 5px;\n}\n\n.centerBottom {\n    position: absolute;\n    left: 50%;\n    bottom: 0;\n    transform: translateX(-50%);\n    color: white;\n}\n\n.centerBottom p {\n    font-weight: bold;\n    color: white;\n}\n\n.bottomRight {\n    position: absolute;\n    right: 0;\n    bottom: 40px;\n    background-color: rgba(0, 0, 0, 0.5);\n    padding: 5px;\n}\n\n.bottomRight p {\n    color: #fff;\n    width: 120px;\n    margin: 0;\n    font-weight: bold;\n    font-style: italic;\n    font-size: 13px;\n}\n\n.carousel .btns {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 20px;\n}\n\n.button {\n    background-color: transparent;\n    border: 2px solid #2E0830;\n    color: #2E0830;\n    padding: 2px 5px;\n    text-align: center;\n    text-decoration: none;\n    display: inline-block;\n    font-size: 16px;\n    margin: 4px 2px;\n    cursor: pointer;\n}\n\n.button:disabled {\n    background-color: #cccccc;\n    cursor: not-allowed;\n}\n\n@media (max-width: 480px) {\n    .card {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageContainer": `styles_imageContainer__5pLq4`,
	"card": `styles_card__Hqqt6`,
	"centerBottom": `styles_centerBottom__ddo1o`,
	"bottomRight": `styles_bottomRight__Z4o2H`,
	"carousel": `styles_carousel__QM87f`,
	"btns": `styles_btns__HHXB6`,
	"button": `styles_button__iXXBm`
};
export default ___CSS_LOADER_EXPORT___;
