// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
import frTranslation from './locales/fr.json';


// Example translations (you can replace with your own)
const resources = {
    en: {
        translation: enTranslation.translation,
    },
    fr: {
        translation: frTranslation.translation,
    },
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en', // default language
        fallbackLng: 'en', // fallback language if translation not found
        interpolation: {
            escapeValue: false, // react already safe from xss
        },
    });

export default i18n;
