import { S3 } from "aws-sdk";

const s3 = new S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_TOKEN_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
});

const uploadToS3 = async (file, folder, fileName) => {
    if (!file) {
        console.log("Upload function, file not found");
        return;
    }

    const BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME;
    const params = {
        Bucket: BUCKET,
        Key: `${folder}/${fileName}`,
        Body: file,
    };
    try {
        const upload = s3.upload(params);
        upload.on("httpUploadProgress", (p) => {
            console.log(p.loaded / p.total);
        });
        const result = await upload.promise();
        // const fileUrl = `https://${BUCKET}.s3.amazonaws.com/${folder}/${fileName}`;

        return result;
    } catch (err) {
        console.log("Upload function error", err);
        return
    }
};

export default uploadToS3;
