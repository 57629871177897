import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import { PiMapPinAreaBold } from "react-icons/pi";
import { getFeaturedUniversities } from "../../../redux/reducers/university";
import { useDispatch } from "react-redux";

const images = [
  "/images/universities/image1.webp",
  "/images/universities/image2.jpg",
  "/images/universities/image3.jpeg",
  "/images/universities/image4.jpg",
  "/images/universities/image5.jpg",
];

const FeaturedInstitutions = () => {
  const [universities, setUniversitiesArray] = useState([]);
  const dispatch = useDispatch();

  const handleGetUniversities = async () => {
    try {
      await dispatch(getFeaturedUniversities()).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          // toast.error(res.payload);
          return;
        } else {
          setUniversitiesArray(res.payload);
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleGetUniversities();
  }, []);

  const getUniqueRandomImage = (index) => {
    // Ensure the index is within bounds
    const imageIndex = index % images.length;
    return images[imageIndex];
  };
  return (
    <div>
      {universities?.map((item, index) => {
        return (
          <div
            className={index % 2 === 1 ? styles.wrapperReverse : styles.wrapper}
            key={index}
          >
            <div
              className={styles.image}
              style={{
                backgroundImage: `url(${
                  JSON.parse(item?.coverPhoto)?.Location
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <img
                src={
                  item.image
                    ? JSON.parse(item.image).Location
                    : "/logos/logo_icon.png"
                }
              />
            </div>
            <div className={styles.content}>
              <a
                className={styles.university}
                href={item.websiteUrl}
                target="_blank"
              >
                <span>
                  {item.name}({item.abbreviation})
                </span>{" "}
                - <i>{item.programCount} Programs</i>
              </a>
              <div className={styles.location}>
                <PiMapPinAreaBold size={20} /> <span>{item.location.city}</span>
                , <span>{item.location.stateOrProvince}</span>
              </div>
              <div className={styles.scholatships}>
                <p className={item.hasSchorlaships ? "" : styles.lineThrough}>
                  SCHOLARSHIPS AVAILABLE
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FeaturedInstitutions;
