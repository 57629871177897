// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_sliderContainer__zO1Zo {
    position: relative;
    width: 50px;
    height: 25px;
}

.styles_slider__MmN0n {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 25px;
    cursor: pointer;
    transition: 0.4s;
}

.styles_slider__MmN0n::before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    border-radius: 50%;
    background-color: white;
    transition: 0.4s;
    margin: 2px;
}

.styles_sliderChecked__-XOda {
    background-color: #67126B;
}

.styles_sliderChecked__-XOda::before {
    transform: translateX(24px);
}

.styles_input__hdMw\\+ {
    opacity: 0;
    width: 0;
    height: 0;
}

.styles_input__hdMw\\+:checked+.styles_slider__MmN0n {
    background-color: #67126B;
}

.styles_input__hdMw\\+:checked+.styles_slider__MmN0n::before {
    transform: translateX(24px);
}`, "",{"version":3,"sources":["webpack://./src/components/inputs/CustomSliderButton/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,uBAAuB;IACvB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,UAAU;IACV,QAAQ;IACR,SAAS;AACb;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".sliderContainer {\n    position: relative;\n    width: 50px;\n    height: 25px;\n}\n\n.slider {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: #ccc;\n    border-radius: 25px;\n    cursor: pointer;\n    transition: 0.4s;\n}\n\n.slider::before {\n    position: absolute;\n    content: \"\";\n    height: 21px;\n    width: 21px;\n    border-radius: 50%;\n    background-color: white;\n    transition: 0.4s;\n    margin: 2px;\n}\n\n.sliderChecked {\n    background-color: #67126B;\n}\n\n.sliderChecked::before {\n    transform: translateX(24px);\n}\n\n.input {\n    opacity: 0;\n    width: 0;\n    height: 0;\n}\n\n.input:checked+.slider {\n    background-color: #67126B;\n}\n\n.input:checked+.slider::before {\n    transform: translateX(24px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sliderContainer": `styles_sliderContainer__zO1Zo`,
	"slider": `styles_slider__MmN0n`,
	"sliderChecked": `styles_sliderChecked__-XOda`,
	"input": `styles_input__hdMw+`
};
export default ___CSS_LOADER_EXPORT___;
