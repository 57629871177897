import React from "react";
import About from "../about/About";
import Universities from "../universities/Universities";
import Settings from "../settings/Settings";

const tabList = [
  {
    title: "About",
    href: "about",
  },
  {
    title: "Universities",
    href: "universities",
  },
  {
    title: "Settings",
    href: "settings",
  },
];

const Tab = ({ user }) => {
  return (
    <div class="col-xl-12">
      <div class="card h-auto">
        <div class="card-body">
          <div class="profile-tab">
            <div class="custom-tab-1">
              <ul class="nav nav-tabs">
                {tabList.map((item, index) => {
                  return (
                    <li class="nav-item" key={index}>
                      <a
                        href={`#${item.href}`}
                        data-bs-toggle="tab"
                        class={`nav-link ${index === 0 && "active show"}`}
                      >
                        {item.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
              <div class="tab-content">
                <About user={user} />
                <Universities user={user} />
                <Settings user={user} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tab;
