import React, { useState, useRef } from "react";
import Layout from "../../layouts/Layout";
import AddProgramComponent from "../../components/program/addProgram/AddProgram";
import { ColorRing } from "react-loader-spinner";

const AddProgramPage = () => {
  const [loading, setLoading] = useState(false);
  const addProgramRef = useRef();

  const handleButtonClick = () => {
    if (addProgramRef.current) {
      addProgramRef.current.addProgram();
    }
  };

  return (
    <Layout>
      <AddProgramComponent
        ref={addProgramRef}
        loading={loading}
        setLoading={setLoading}
      />

      <div className="card">
        <div className="card-body">
          <button
            className="btn btn-primary"
            type="submit"
            onClick={handleButtonClick}
            disabled={loading}
          >
            {loading ? (
              <ColorRing
                visible={true}
                height="22"
                width="22"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            ) : (
              "Save Changes"
            )}
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default AddProgramPage;
