import React from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { FaCloudDownloadAlt } from "react-icons/fa";

const GenericPdfDownloader = ({ rootElementId, imageId, imageLink, downloadFileName, disabled }) => {

    const downloadPdfDocument = async () => {
        let input = document.getElementById(rootElementId);

        let image = input.querySelector('img');
        let parent = null;
        let nextSibling = null;

        if (image) {
            parent = image.parentNode;
            nextSibling = image.nextSibling;
            image.remove();
        }

        html2canvas(input, {
            useCORS: true,
            allowTaint: true,
            background: "#fff",
        }).then((canvas) => {
            let img = new Image();
            img.crossOrigin = 'anonymous'
            img.src = imageLink + '?r=' + Math.floor(Math.random() * 100000);

            if (imageId === "letter_logo") {
                img.style.display = 'block';
                img.style.margin = '0 auto';
                img.style.borderRadius = '50%';
            }

            const content = canvas.toDataURL("image/png", 1.0);

            const pdf = new jsPDF({
                orientation: "portrait",
                unit: "px",
                // format: [canvas.width, canvas.height]
                format: "a4"
            });

            if (imageId === "letter_logo") {
                pdf.addImage(img, "PNG", 175, 23, pdf.internal.pageSize.width * 0.2, pdf.internal.pageSize.height * 0.13);
            } else {
                pdf.addImage(img, "PNG", 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height * 0.2);
            }
            pdf.addImage(content, "PNG", 0, pdf.internal.pageSize.height * 0.2, pdf.internal.pageSize.width, pdf.internal.pageSize.height * 0.8);
            // pdf.addImage(imgData, "PNG", 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
            pdf.save(`${downloadFileName}.pdf`);

            // Re-append the image element
            if (image && parent) {
                parent.insertBefore(image, nextSibling);
            }
        });
    };

    return (
        <button className="btn btn-outline-primary" onClick={downloadPdfDocument} disabled={disabled}>
            Download <FaCloudDownloadAlt size={20} />
        </button>
    );
};

export default GenericPdfDownloader;
