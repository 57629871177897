import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const url = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_VERSION}/programs`;
const token = localStorage.getItem("campus-camer-user");
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const initialState = {};

export const getPrograms = createAsyncThunk("programs/getPrograms", async (thunkAPI) => {
    try {
        const response = await axios.get(`${url}/`);
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getProgramsWithUniversity = createAsyncThunk("programs/getProgramsWithUniversity", async (thunkAPI) => {
    try {
        const response = await axios.get(`${url}/program-with-university`);
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getProgramsByUniversities = createAsyncThunk("programs/getProgramsByUniversities", async (universityIds, thunkAPI) => {
    try {
        const response = await axios.get(`${url}/assigened-universities`, {
            params: { universityIds },
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getUniversityPrograms = createAsyncThunk("programs/getUniversityPrograms", async (id, thunkAPI) => {
    try {
        const response = await axios.get(`${url}/universities/${id}`);
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getProgram = createAsyncThunk("programs/getProgram", async (id, thunkAPI) => {
    try {
        const response = await axios.get(`${url}/details/${id}`);
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const addProgram = createAsyncThunk("programs/addProgram", async (data, thunkAPI) => {
    try {
        const response = await axios.post(`${url}/create`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const updateProgram = createAsyncThunk("programs/updateProgram", async (data, thunkAPI) => {
    try {
        const response = await axios.put(`${url}/update/${data.id}`, data, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const advancedSearch = createAsyncThunk("programs/advancedSearch", async (params, thunkAPI) => {
    try {
        const response = await axios.get(`${url}/advanced-search`, { params });
        return response.data;
    } catch (error) {
        const message =
            (error.message && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const programSlice = createSlice({
    name: "programs",
    initialState,
    reducers: {},
    extraReducers: (builder) => { },
});

export const { } = programSlice.actions;
export default programSlice.reducer;
