import React, { useState, useEffect } from "react";
import Loader from "../../Loader/Loader";
import { getAssignedUniversities } from "../../../redux/reducers/university";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

const Universities = ({ user }) => {
  const [universities, setUniversities] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleGetUniversities = async (e) => {
    try {
      setLoading(true);
      await dispatch(
        getAssignedUniversities(
          user.admissionOfficerDetails.assignedUniversities
        )
      ).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          setLoading(false);
          return;
        } else {
          setUniversities(res.payload);
          setLoading(false);
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleGetUniversities();
  }, [user]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div id="universities" className="tab-pane fade">
      <div className="my-post-content pt-3">
        {universities.length > 0
          ? universities.map((item, index) => {
              return (
                <div class="row mb-2" key={index}>
                  <div class="col-sm-3 col-5">
                    <h5 class="f-w-500">
                      Name <span class="pull-end">:</span>
                    </h5>
                  </div>
                  <div class="col-sm-9 col-7">
                    <span>{item.name}</span>
                  </div>
                </div>
              );
            })
          : "No Universities Found"}
      </div>
    </div>
  );
};

export default Universities;
