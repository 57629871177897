import React from "react";
import styles from "./styles.module.css";

const CustomSliderButton = ({ checked, onChange }) => {
  return (
    <label className={styles.sliderContainer}>
      <input
        type="checkbox"
        className={styles.input}
        checked={checked}
        onChange={onChange}
      />
      <span
        className={`${styles.slider} ${checked ? styles.sliderChecked : ""}`}
      ></span>
    </label>
  );
};

export default CustomSliderButton;
