import React from "react";

const Documents = ({ user }) => {
  return (
    <div id="documents" className="tab-pane fade">
      <div className="my-post-content pt-3">
        {user.studentDetails.documents.length > 0
          ? user.studentDetails.documents.map((item, index) => {
              return (
                <div key={index} className="mb-4">
                  <h5 className="text-primary mb-2">{item.title}</h5>
                  <div className="row mb-2">
                    <a className="f-w-500" href={item.link} target="_blank">
                      {item.link}
                    </a>
                  </div>
                </div>
              );
            })
          : "No Documents Found"}
      </div>
    </div>
  );
};

export default Documents;
