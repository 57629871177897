import React, { useState } from "react";
import Layout from "../../layouts/Layout";
import { useDispatch } from "react-redux";
import { addUniversity } from "../../redux/reducers/university";
import toast from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import uploadToS3 from "../../utils/awsS3Upload";

const AddUniversityPage = () => {
  const [name, setName] = useState("");
  const [abbreviation, setAbbreviation] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [signatory, setSignatory] = useState("");

  const [websiteUrl, setWebsiteUrl] = useState("");
  const [bankName, setBankName] = useState("");
  const [momoNumber, setMomoNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Files
  const [logo, setLogo] = useState(null);
  const [preview, setPreview] = useState(null);

  const [letterHead, setLetterHead] = useState(null);
  const [preview2, setPreview2] = useState(null);

  const [coverPhoto, setCoverPhoto] = useState(null);
  const [preview3, setPreview3] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogo(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLetterChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLetterHead(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview2(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCoverPhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCoverPhoto(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview3(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddUniversity = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res1 = await uploadToS3(logo, "logo", logo?.name);
      const res2 = await uploadToS3(
        letterHead,
        "letter-head",
        letterHead?.name
      );
      const res3 = await uploadToS3(
        coverPhoto,
        "cover-photo",
        coverPhoto?.name
      );
      if (res1 && res2 && res3) {
        const data = {
          image: JSON.stringify(res1),
          letterHead: JSON.stringify(res2),
          coverPhoto: JSON.stringify(res3),
          name: name,
          abbreviation: abbreviation,
          location: {
            city: city,
            stateOrProvince: stateProvince,
            country: country,
          },
          description: description,
          websiteUrl: websiteUrl,
          contactInformation: {
            email: email,
            phone: phone,
          },
          paymentDetails: {
            bankTransfer: {
              nameOfBank: bankName,
              accountNumber: accountNumber,
            },
            mobileMoney: momoNumber,
          },
          signatory: signatory,
        };
        await dispatch(addUniversity(data)).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setLoading(false);
            return;
          } else {
            toast.success(res.payload.message);
            window.location.href = "/universities";
            setLoading(false);
          }
        });
      } else {
        toast.error("an error occured");
        setLoading(false);
        return;
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h5 class="mb-0">University Details</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-xl-3 col-lg-4">
                  <label class="form-label text-primary">
                    Logo<span class="required">*</span>
                  </label>
                  <div class="avatar-upload">
                    <div class="avatar-preview">
                      {preview && (
                        <img
                          src={preview}
                          alt="Preview"
                          style={{ width: "100px", height: "100px" }}
                        />
                      )}
                    </div>
                    <div class="change-btn mt-2 mb-lg-0 mb-3">
                      <input
                        type="file"
                        class="form-control d-none"
                        id="logoUpload"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleFileChange}
                      />

                      <label
                        for="logoUpload"
                        class="dlab-upload mb-0 btn btn-primary btn-sm"
                      >
                        Choose File
                      </label>
                      {/* <button
                        class="btn btn-success light remove-img ms-2 btn-sm"
                        onClick={textUpload}
                      >
                        Save
                      </button> */}
                    </div>
                  </div>

                  <label class="form-label text-primary mt-5">
                    Letter Head<span class="required">*</span>
                  </label>
                  <div class="avatar-upload">
                    <div class="avatar-preview">
                      {preview2 && (
                        <img
                          src={preview2}
                          alt="Preview"
                          style={{ width: "250px", height: "100px" }}
                        />
                      )}
                    </div>
                    <div class="change-btn mt-2 mb-lg-0 mb-3">
                      <input
                        type="file"
                        class="form-control d-none"
                        id="letterHead"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleLetterChange}
                      />
                      <label
                        for="letterHead"
                        class="dlab-upload mb-0 btn btn-primary btn-sm"
                      >
                        Choose File
                      </label>
                      {/* <button class="btn btn-success light remove-img ms-2 btn-sm">
                        Save
                      </button> */}
                    </div>
                  </div>

                  <label class="form-label text-primary mt-5">
                    Cover Photo<span class="required">*</span>
                  </label>
                  <div class="avatar-upload">
                    <div class="avatar-preview">
                      {preview3 && (
                        <img
                          src={preview3}
                          alt="Preview"
                          style={{ width: "250px", height: "100px" }}
                        />
                      )}
                    </div>
                    <div class="change-btn mt-2 mb-lg-0 mb-3">
                      <input
                        type="file"
                        class="form-control d-none"
                        id="coverPhoto"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleCoverPhotoChange}
                      />
                      <label
                        for="coverPhoto"
                        class="dlab-upload mb-0 btn btn-primary btn-sm"
                      >
                        Choose File
                      </label>
                      {/* <button class="btn btn-success light remove-img ms-2 btn-sm">
                        Save
                      </button> */}
                    </div>
                  </div>
                </div>
                <div class="col-xl-9 col-lg-8">
                  <div class="row">
                    <div class="col-xl-6 col-sm-6">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label text-primary"
                        >
                          University Name<span class="required">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput1"
                          placeholder="University Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput3"
                          class="form-label text-primary"
                        >
                          City<span class="required">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput3"
                          placeholder="city"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput5"
                          class="form-label text-primary"
                        >
                          Email<span class="required">*</span>
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleFormControlInput5"
                          placeholder="email@university.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div class="mb-3">
                        <label
                          for="exampleFormControlTextarea1"
                          class="form-label text-primary"
                        >
                          Description<span class="required">*</span>
                        </label>
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="6"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-xl-6 col-sm-6">
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput4"
                          class="form-label text-primary"
                        >
                          Abbreviation (MAX 3 Letters Long)
                          <span class="required">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput4"
                          placeholder="e.g CCM"
                          value={abbreviation}
                          onChange={(e) => setAbbreviation(e.target.value)}
                        />
                      </div>
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput4"
                          class="form-label text-primary"
                        >
                          State/Province<span class="required">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput4"
                          placeholder="Littoral"
                          value={stateProvince}
                          onChange={(e) => setStateProvince(e.target.value)}
                        />
                      </div>
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput5"
                          class="form-label text-primary"
                        >
                          Country<span class="required">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput5"
                          placeholder="Cameroon"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                      </div>
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput6"
                          class="form-label text-primary"
                        >
                          Phone Number<span class="required">*</span>
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          id="exampleFormControlInput6"
                          placeholder="+123456789"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          for="exampleFormControlInput6"
                          class="form-label text-primary"
                        >
                          University Signatory<span class="required">*</span>
                        </label>
                        <input
                          type="text"
                          placeholder="signatory"
                          className="form-control"
                          value={signatory}
                          onChange={(e) => setSignatory(e.target.value)}
                        />
                      </div>
                      <div class="mb-3">
                        <label
                          for="exampleFormControlInput5"
                          class="form-label text-primary"
                        >
                          Webiste Url<span class="required">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleFormControlInput5"
                          placeholder="https://university.com"
                          value={websiteUrl}
                          onChange={(e) => setWebsiteUrl(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header">
            <h5 class="mb-0">Payment Details</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6 col-sm-6">
                <div class="mb-3">
                  <label
                    for="exampleFormControlInput8"
                    class="form-label text-primary"
                  >
                    Bank Name<span class="required">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput8"
                    placeholder="Bank Atlantic"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label
                    for="exampleFormControlInput9"
                    class="form-label text-primary"
                  >
                    Momo Number<span class="required">*</span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="exampleFormControlInput9"
                    placeholder="+237672777777"
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                  />
                </div>
              </div>

              <div class="col-xl-6 col-sm-6">
                <div class="mb-3">
                  <label
                    for="exampleFormControlInput10"
                    class="form-label text-primary"
                  >
                    Account Number<span class="required">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput10"
                    placeholder="332734287346834"
                    value={momoNumber}
                    onChange={(e) => setMomoNumber(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div class="">
              <button
                class="btn btn-primary"
                type="button"
                onClick={handleAddUniversity}
                disabled={loading}
              >
                {loading ? (
                  <ColorRing
                    visible={true}
                    height="22"
                    width="22"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                  />
                ) : (
                  "Save Changes"
                )}
              </button>
              <a class="btn btn-outline-primary mx-3" href="/universities">
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddUniversityPage;
