import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";

const ChangeLanguage = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className={styles.language}>
      {/* <select onChange={(e) => changeLanguage(e.target.value)}>
        <option value="en">En</option>
        <option value="fr">Fr</option>
      </select> */}
    </div>
  );
};

export default ChangeLanguage;
