import React, { useEffect, useState } from "react";
import { updateUniversity } from "../../../redux/reducers/university";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { ColorRing } from "react-loader-spinner";
import uploadToS3 from "../../../utils/awsS3Upload";
import CustomSliderButton from "../../inputs/CustomSliderButton/CustomSliderButton";

const Settings = ({ university }) => {
  const [featured, setFeatured] = useState(false);
  const [hasSchorlaships, setHasSchorlaships] = useState(false);

  const [name, setName] = useState("");
  const [abbreviation, setAbbreviation] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [signatory, setSignatory] = useState("");

  const [bankName, setBankName] = useState("");
  const [momoNumber, setMomoNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Files
  const [logo, setLogo] = useState(null);
  const [preview, setPreview] = useState(null);

  const [letterHead, setLetterHead] = useState(null);
  const [preview2, setPreview2] = useState(null);

  const [coverPhoto, setCoverPhoto] = useState(null);
  const [preview3, setPreview3] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogo(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLetterChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLetterHead(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview2(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCoverPhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCoverPhoto(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview3(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateUniversity = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res1 = await uploadToS3(logo, "logo", logo?.name);
      const res2 = await uploadToS3(
        letterHead,
        "letter-head",
        letterHead?.name
      );
      const res3 = await uploadToS3(
        coverPhoto,
        "cover-photo",
        coverPhoto?.name
      );
      console.log(res3);
      const data = {
        id: university._id,
        image: JSON.stringify(res1),
        letterHead: JSON.stringify(res2),
        coverPhoto: JSON.stringify(res3),
        name: name,
        abbreviation: abbreviation,
        location: {
          city: city,
          stateOrProvince: stateProvince,
          country: country,
        },
        description: description,
        websiteUrl: websiteUrl,
        contactInformation: {
          email: email,
          phone: phone,
        },
        paymentDetails: {
          bankTransfer: {
            nameOfBank: bankName,
            accountNumber: accountNumber,
          },
          mobileMoney: momoNumber,
        },
        signatory: signatory,
        featured: featured,
        hasSchorlaships: hasSchorlaships,
      };
      await dispatch(updateUniversity(data)).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          setLoading(false);
          return;
        } else {
          toast.success(res.payload.message);
          setLoading(false);
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setFeatured(university.featured);
    setHasSchorlaships(university.hasSchorlaships);

    setName(university.name);
    setAbbreviation(university.abbreviation);
    setEmail(university.contactInformation.email);
    setPhone(university.contactInformation.phone);
    setDescription(university.description);
    setCity(university.location.city);
    setStateProvince(university.location.stateOrProvince);
    setCountry(university.location.country);
    setWebsiteUrl(university.websiteUrl);
    setSignatory(university.signatory);

    setBankName(university.paymentDetails?.bankTransfer.nameOfBank);
    setAccountNumber(university.paymentDetails?.bankTransfer.accountNumber);
    setMomoNumber(university.paymentDetails?.mobileMoney);
  }, [university]);

  return (
    <div id="settings" className="tab-pane fade">
      <div className="pt-3">
        <div className="settings-form">
          <h5 className="text-primary">University Details</h5>
          <form>
            <div class="row  mb-5 mt-5">
              <div class="col-md-4">
                <label class="form-label text-primary">
                  Logo<span class="required">*</span>
                </label>
                <div class="avatar-upload">
                  <div class="avatar-preview">
                    {preview && (
                      <img
                        src={preview}
                        alt="Preview"
                        style={{ width: "100px", height: "100px" }}
                      />
                    )}
                  </div>
                  <div class="change-btn mt-2 mb-lg-0 mb-3">
                    <input
                      type="file"
                      class="form-control d-none"
                      id="logoUpload"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleFileChange}
                    />

                    <label
                      for="logoUpload"
                      class="dlab-upload mb-0 btn btn-primary btn-sm"
                    >
                      Choose File
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <label class="form-label text-primary">
                  Letter Head<span class="required">*</span>
                </label>
                <div class="avatar-upload">
                  <div class="avatar-preview">
                    {preview2 && (
                      <img
                        src={preview2}
                        alt="Preview"
                        style={{ width: "250px", height: "100px" }}
                      />
                    )}
                  </div>
                  <div class="change-btn mt-2 mb-lg-0 mb-3">
                    <input
                      type="file"
                      class="form-control d-none"
                      id="letterHead"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleLetterChange}
                    />
                    <label
                      for="letterHead"
                      class="dlab-upload mb-0 btn btn-primary btn-sm"
                    >
                      Choose File
                    </label>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <label class="form-label text-primary">
                  Cover Photo<span class="required">*</span>
                </label>
                <div class="avatar-upload">
                  <div class="avatar-preview">
                    {preview3 && (
                      <img
                        src={preview3}
                        alt="Preview"
                        style={{ width: "250px", height: "100px" }}
                      />
                    )}
                  </div>
                  <div class="change-btn mt-2 mb-lg-0 mb-3">
                    <input
                      type="file"
                      class="form-control d-none"
                      id="coverPhoto"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleCoverPhotoChange}
                    />
                    <label
                      for="coverPhoto"
                      class="dlab-upload mb-0 btn btn-primary btn-sm"
                    >
                      Choose File
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <div className="form-label">Featured On Site</div>
                <CustomSliderButton
                  checked={featured}
                  onChange={() => setFeatured(!featured)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <div className="form-label">Offer Schorlaships</div>
                <CustomSliderButton
                  checked={hasSchorlaships}
                  onChange={() => setHasSchorlaships(!hasSchorlaships)}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label">University Name</label>
                <input
                  type="text"
                  placeholder="University Name"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label">
                  Abbreviation (MAX 3 Letters Long)
                </label>
                <input
                  type="text"
                  placeholder="e.g CCM"
                  className="form-control"
                  value={abbreviation}
                  onChange={(e) => setAbbreviation(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label">Phone Number</label>
                <input
                  type="number"
                  placeholder="Phone Number"
                  className="form-control"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label">State/Province</label>
                <input
                  type="text"
                  placeholder="State/Province"
                  className="form-control"
                  value={stateProvince}
                  onChange={(e) => setStateProvince(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label">Country</label>
                <input
                  type="text"
                  placeholder="Country"
                  className="form-control"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>

              <div className="mb-3 col-md-6">
                <label className="form-label">City</label>
                <input
                  type="text"
                  placeholder="City"
                  className="form-control"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label">Description</label>
                <textarea
                  placeholder="Description"
                  className="form-control"
                  rows="6"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label">Website URL</label>
                <input
                  type="text"
                  placeholder="Website URL"
                  className="form-control"
                  value={websiteUrl}
                  onChange={(e) => setWebsiteUrl(e.target.value)}
                />
                <div className="mt-3">
                  <label className="form-label">University Signatory</label>
                  <input
                    type="text"
                    placeholder="signatory"
                    className="form-control"
                    value={signatory}
                    onChange={(e) => setSignatory(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <h5 className="text-primary mt-5">Payment Details</h5>
            <div className="row">
              <div className="mb-3 col-md-6">
                <label className="form-label">Bank Name</label>
                <input
                  type="text"
                  placeholder="Bank Name"
                  className="form-control"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label">Momo Number</label>
                <input
                  type="number"
                  placeholder="Momo Number"
                  className="form-control"
                  value={momoNumber}
                  onChange={(e) => setMomoNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Account Number</label>
              <input
                type="text"
                placeholder="Account Number"
                className="form-control"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </div>

            <button
              className="btn btn-primary"
              type="submit"
              onClick={handleUpdateUniversity}
              disabled={loading}
            >
              {loading ? (
                <ColorRing
                  visible={true}
                  height="22"
                  width="22"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />
              ) : (
                "Update"
              )}
            </button>
          </form>

          {/* <div
            className="card border-danger bg-light text-danger"
            style={{ marginTop: 120 }}
          >
            <div className="card-header bg-danger text-white">
              <h4 className="text-white">Danger Section</h4>
            </div>
            <div className="card-body">
              <p className="card-text">
                Deleting this university is a permanent action and cannot be
                undone. All your data will be lost. Please be sure before
                proceeding.
              </p>
              <button
                type="button"
                className="btn btn-danger"
                data-toggle="modal"
                data-target="#confirmDeleteModal"
              >
                Delete University
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Settings;
