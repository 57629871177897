import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../redux/reducers/user";
import toast from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";

const Login = () => {
  const token = localStorage.getItem("campus-camer-user");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token) {
      window.location.href = "/dashboard";
    }
  }, [token, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      if (email && password) {
        setLoading(true);
        await dispatch(login({ emailAddress: email, password })).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setLoading(false);
            return;
          } else {
            toast.success(res.payload.message);
            window.location.href = "/dashboard";
            setLoading(false);
            return;
          }
        });
      } else {
        toast.error("email and password required");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="login-aside text-center  d-flex flex-column flex-row-auto">
        <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <div className="text-center mb-lg-4 mb-2 pt-5 logo">
            <img src="/logos/logo_white.png" alt="" style={{ width: "50%" }} />
          </div>
          <h3 className="mb-2 text-white">Welcome back!</h3>
          <p className="mb-4">
            Start Your Academic Journey Locally <br /> and Achieve Your Dreams.
          </p>
        </div>
        <div
          className="aside-image position-relative"
          style={{ backgroundImage: "url(images/background/pic-2.png)" }}
        >
          {/* <img
            className="img1 move-1"
            src="images/background/pic3.png"
            alt=""
          />
          <img
            className="img2 move-2"
            src="images/background/pic4.png"
            alt=""
          />
          <img
            className="img3 move-3"
            src="images/background/pic5.png"
            alt=""
          /> */}
        </div>
      </div>
      <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <div className="authincation-content style-2">
            <div className="row no-gutters">
              <div className="col-xl-12 tab-content">
                <div
                  id="sign-up"
                  className="auth-form tab-pane fade show active  form-validation"
                >
                  <form action="index.html">
                    <div className="text-center mb-4">
                      <h3 className="text-center mb-2 text-black">Sign In</h3>
                      <span>Welcome to Campus Camer</span>
                    </div>

                    {/* <div className="sepertor">
                      <span className="d-block mb-4 fs-13">Or with email</span>
                    </div> */}
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label mb-2 fs-13 label-color font-w500"
                      >
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="hello@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label mb-2 fs-13 label-color font-w500"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="exampleFormControlInput2"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <a
                      href="javascript:void(0);"
                      className="text-primary float-end mb-4"
                    >
                      Forgot Password ?
                    </a>
                    <button
                      className="btn btn-block btn-primary"
                      onClick={handleLogin}
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <ColorRing
                          visible={true}
                          height="22"
                          width="22"
                          ariaLabel="color-ring-loading"
                          wrapperStyle={{}}
                          wrapperClass="color-ring-wrapper"
                          colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                        />
                      ) : (
                        "Sign In"
                      )}
                    </button>

                    <div className="mt-2">
                      Don't have an account?{" "}
                      <a
                        href="/register"
                        className="text-primary float-end mb-4"
                      >
                        Register
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
