import React, { useState, useContext } from "react";
import styles from "./ProgramItem.module.css";
import {
  FaMapMarkerAlt,
  FaRegCalendarAlt,
  FaRegMoneyBillAlt,
  FaExternalLinkAlt,
  FaClipboard,
} from "react-icons/fa";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import Tab from "../programDetailsTab/Tab";
import { formatMoney } from "../../../utils/formatMoney";
import moment from "moment";
import { AuthContext } from "../../../context/AuthContext";
import toast from "react-hot-toast";
import { addApplication } from "../../../redux/reducers/application";
import { useDispatch } from "react-redux";
import { ColorRing } from "react-loader-spinner";

const ProgramItem = ({ program }) => {
  const { authenticated, role, userId, username } = useContext(AuthContext);
  const [showTab, setShowTab] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const dispatch = useDispatch();

  const handleAddApplication = async (e) => {
    e.preventDefault();
    try {
      if (authenticated) {
        if (role === "STUDENT") {
          setSubmitLoading(true);
          const data = {
            studentId: userId,
            programId: program._id,
            universityId: program.universityId._id,
            studentName: username,
            programName: program.name,
            universityName: program.universityId.name,
            abbreviation: program.universityId.abbreviation,
          };
          await dispatch(addApplication(data)).then((res) => {
            if (res.meta.requestStatus === "rejected") {
              toast.error(res.payload);
              setSubmitLoading(false);
              return;
            } else {
              toast.success(res.payload.message);
              setSubmitLoading(false);
              window.location.href = `/program-details?=${program._id}`;
              return;
            }
          });
        } else {
          toast.error("Log Into Your Student Account");
          return;
        }
      } else {
        toast.error("Login To Apply");
        return;
      }
    } catch (error) {
      console.log("error", error);
      return;
    }
  };

  return (
    <div className={styles.program}>
      <div className={styles.topSection}>
        <div className={styles.content}>
          <img
            src={
              JSON.parse(program.universityId?.image)?.Location ||
              "/images/2.jpg"
            }
            alt="Logo"
          />
          <div className={styles.details}>
            <div className={styles.university}>
              <a href={program.universityId.websiteUrl} target="_blank">
                {program.universityId.name} <FaExternalLinkAlt />
              </a>
              <span>
                <FaMapMarkerAlt />
                {program.universityId.location.city} |{" "}
                {program.universityId.location.stateOrProvince}
              </span>
            </div>
            <h3>{program.name}</h3>
            <div className={styles.programDetails}>
              <div className={styles.detailDiv}>
                <div className={styles.detailIcon}>
                  <FaRegMoneyBillAlt />
                </div>
                <div className={styles.detail}>
                  <p>Tuition</p>
                  <p>{formatMoney(program.tuitionFee)} XAF</p>
                </div>
              </div>
              <div className={styles.detailDiv}>
                <div className={styles.detailIcon}>
                  <FaRegCalendarAlt />
                </div>
                <div className={styles.detail}>
                  <p>Apply Before</p>
                  <p>
                    {moment(program.applicationDeadline).format("MMM D, YYYY")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.applyBtn}>
          <button
            className="btn btn-primary"
            disabled={submitLoading ? true : false}
            onClick={handleAddApplication}
          >
            {submitLoading ? (
              <ColorRing
                visible={true}
                height="22"
                width="22"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            ) : (
              <>
                <FaClipboard /> Apply
              </>
            )}
          </button>
        </div>
      </div>
      <div className={styles.info}>
        <div>
          <button
            className={styles.infoBtn}
            onClick={() => setShowTab(!showTab)}
          >
            More Info {showTab ? <MdExpandLess /> : <MdExpandMore />}
          </button>
        </div>
      </div>
      {showTab && <Tab program={program} university={program.universityId} />}
    </div>
  );
};

export default ProgramItem;
