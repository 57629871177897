import React from "react";

const Conditions = ({ program }) => {
  return (
    <div id="conditions" class="tab-pane fade">
      <div class="pt-4 profile-personal-info">
        <h5 class="text-primary mb-4">Conditions</h5>
        {program.conditionsForAcceptance.length > 0
          ? program.conditionsForAcceptance.map((item, index) => {
              return (
                <div class="row mb-2" key={index}>
                  <div class="col-sm-3 col-5">
                    <h5 class="f-w-500">
                      {item.title} <span class="pull-end">:</span>
                    </h5>
                  </div>
                  <div class="col-sm-9 col-7">
                    <span>{item.value}</span>
                  </div>
                </div>
              );
            })
          : "No Requirements Added"}
      </div>
    </div>
  );
};

export default Conditions;
