import React from "react";

const About = ({ user }) => {
  return (
    <div id="about" class="tab-pane fade active show">
      <div class="pt-4 profile-personal-info">
        <h5 class="text-primary mb-4">Personal Information</h5>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Phone Number <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{user.phoneNumber}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Email <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{user.emailAddress}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Authorization Level <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>Level {user.authorizationLevel}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
