import React, { useState, useEffect } from "react";
import styles from "./Widget.module.css";
import Dropdown from "../../inputs/selectDropdown/Dropdown";
import { useDispatch } from "react-redux";
import { getCities } from "../../../redux/reducers/university";
import toast from "react-hot-toast";
import { getProgramsWithUniversity } from "../../../redux/reducers/program";

const Widget = ({
  // programs,
  // Functions
  setProgram,
  setLocation,
  setLevelOfStudy,
  onClick,
}) => {
  const dispatch = useDispatch();
  const [citiesArray, setCitiesArray] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [uniqueProgramNames, setUniqueProgramNamesArray] = useState([]);

  const handleGetPrograms = async (e) => {
    try {
      // setLoading(true);
      await dispatch(getProgramsWithUniversity()).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          // setLoading(false);
          return;
        } else {
          setPrograms(res.payload);
          // setListPrograms(res.payload);
          // setLoading(false);
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleGetCities = async () => {
    try {
      await dispatch(getCities()).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          return;
        } else {
          const uniqueCities = res.payload.map((item) => item.city);
          setCitiesArray(uniqueCities);
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleGetPrograms();
    handleGetCities();
  }, []);

  useEffect(() => {
    if (programs.length > 0) {
      const programNames = programs?.map((program) => program.name);
      const uniqueProgramNamesSet = new Set(programNames);
      const uniqueProgramNamesArray = Array.from(uniqueProgramNamesSet);
      setUniqueProgramNamesArray(uniqueProgramNamesArray);
    }
  }, [programs]);

  const programLevels = [
    "Undergraduate Programs",
    "Postgraduate Programs",
    "Doctoral Programs",
    "Professional Programs",
    "Diplomas and Certificates",
    "Technical and Vocational Education",
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.optionSection}>
        <p>Location</p>
        <Dropdown options={citiesArray} handleChange={setLocation} />
      </div>
      <div className={styles.optionSection}>
        <p>Program Level</p>
        <Dropdown options={programLevels} handleChange={setLevelOfStudy} />
      </div>
      <div className={styles.optionSection}>
        <p>Program</p>
        <Dropdown options={uniqueProgramNames} handleChange={setProgram} />
      </div>
      <div className={styles.optionSection}>
        <button className="btn btn-primary" onClick={onClick}>
          Search
        </button>
      </div>
    </div>
  );
};

export default Widget;
