import React, { useEffect, useState, useContext } from "react";
import { updateUser } from "../../../redux/reducers/user";
import { getUniversities } from "../../../redux/reducers/university";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { ColorRing } from "react-loader-spinner";
import uploadToS3 from "../../../utils/awsS3Upload";
import { AuthContext } from "../../../context/AuthContext";
import CustomMultiSelect from "../../inputs/CustomMultiSelect/CustomMultiSelect";

const Settings = ({ user }) => {
  const { role } = useContext(AuthContext);
  // Common fields
  const [userRole, setRole] = useState("");
  const [image, setImage] = useState("");
  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nationalIDNumber, setNationalIDNumber] = useState("");
  const [citizenship, setCitizenship] = useState("");
  const [authorizationLevel, setAuthorizationLevel] = useState(4);
  const [password, setPassword] = useState("");

  // Admission Officer-specific fields
  const [assignedUniversityIDs, setAssignedUniversityIDs] = useState([]);
  const [assignedUniversities, setAssignedUniversities] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Files
  const [profileImage, setProfileImage] = useState(null);
  const [preview, setPreview] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const extractIds = (array) => {
    return array?.map((item) => item?._id);
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (profileImage) {
        const res = await uploadToS3(
          profileImage,
          "profile-image",
          profileImage?.name
        );
        if (res) {
          const data = {
            id: user._id,
            role: userRole,
            image: JSON.stringify(res),
            fullName: fullName,
            emailAddress: emailAddress,
            phoneNumber: phoneNumber,
            citizenship: citizenship,
            nationalIDNumber: nationalIDNumber,
            authorizationLevel: authorizationLevel,
            password: password,
            // admission officer details
            admissionOfficerDetails: {
              assignedUniversities: extractIds(assignedUniversities),
            },
          };
          await dispatch(updateUser(data)).then((res) => {
            if (res.meta.requestStatus === "rejected") {
              toast.error(res.payload);
              setLoading(false);
              return;
            } else {
              toast.success(res.payload.message);
              setLoading(false);
              return;
            }
          });
        } else {
          toast.error("an error occured");
          setLoading(false);
          return;
        }
      } else {
        const data = {
          id: user._id,
          role: userRole,
          fullName: fullName,
          emailAddress: emailAddress,
          phoneNumber: phoneNumber,
          citizenship: citizenship,
          nationalIDNumber: nationalIDNumber,
          authorizationLevel: authorizationLevel,
          password: password,
          // admission officer details
          admissionOfficerDetails: {
            assignedUniversities: extractIds(assignedUniversities),
          },
        };
        await dispatch(updateUser(data)).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setLoading(false);
            return;
          } else {
            toast.success(res.payload.message);
            setLoading(false);
            return;
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setRole(user.role);
    setImage(user.image);
    setFullName(user.fullName);
    setEmailAddress(user.emailAddress);
    setPhoneNumber(user.phoneNumber);
    setNationalIDNumber(user.nationalIDNumber);
    setCitizenship(user.citizenship);
    setAuthorizationLevel(user.authorizationLevel);
    // Check if user has admissionOfficerDetails
    if (user.admissionOfficerDetails) {
      setAssignedUniversityIDs(
        user.admissionOfficerDetails.assignedUniversities || []
      );
    }
  }, [user]);

  useEffect(() => {
    const getUniversitiesByIds = (universities, ids) => {
      return universities.filter((university) => ids.includes(university._id));
    };
    setAssignedUniversities(
      getUniversitiesByIds(universities, assignedUniversityIDs)
    );
  }, [universities, assignedUniversityIDs]);

  const handleUniversitiesChange = (selectedOptions) => {
    setAssignedUniversities(selectedOptions);
  };

  const handleGetUniversities = async (e) => {
    try {
      setLoading(true);
      await dispatch(getUniversities()).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error(res.payload);
          setLoading(false);
          return;
        } else {
          setUniversities(res.payload);
          setLoading(false);
          return;
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleGetUniversities();
  }, []);

  return (
    <div id="settings" className="tab-pane fade">
      <div className="pt-3">
        <div className="settings-form">
          <h5 className="text-primary">Account Setting</h5>
          <form>
            <div className="row mb-5 mt-5">
              <label class="form-label text-primary">Profile Iimage</label>
              <div class="avatar-upload">
                <div class="avatar-preview">
                  {preview && (
                    <img
                      src={preview}
                      alt="Preview"
                      style={{ width: "100px", height: "100px" }}
                    />
                  )}
                </div>
                <div class="change-btn mt-2 mb-lg-0 mb-3">
                  <input
                    type="file"
                    class="form-control d-none"
                    id="logoUpload"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleFileChange}
                  />

                  <label
                    for="logoUpload"
                    class="dlab-upload mb-5 btn btn-primary btn-sm"
                  >
                    Choose File
                  </label>
                </div>
              </div>

              {role === "ADMIN" && (
                <div className="mb-3 col-md-6 ">
                  <label className="form-label text-primary">
                    Role <span class="required">*</span>
                  </label>
                  <select
                    className="form-control"
                    value={userRole}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value="">Select Role</option>
                    <option value="AECO_ADMIN">AECO ADMIN</option>
                    <option value="ADMISSION_OFFICER">UNIVERSITY ADMIN</option>
                    <option value="COUNSELLOR">COUNSELLOR</option>
                  </select>
                </div>
              )}
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">
                  Full Name <span class="required">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Full Name"
                  className="form-control"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>

              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">Citizenship</label>
                <input
                  type="text"
                  className="form-control"
                  value={citizenship}
                  onChange={(e) => setCitizenship(e.target.value)}
                  placeholder="Citizenship"
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">
                  Passport or NIC Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={nationalIDNumber}
                  onChange={(e) => setNationalIDNumber(e.target.value)}
                  placeholder="Passport or NIC Number"
                />
              </div>

              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">
                  Email <span class="required">*</span>
                </label>
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">Phone Number</label>
                <input
                  type="number"
                  placeholder="Phone Number"
                  className="form-control"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>

              {role === "ADMIN" && (
                <div className="mb-3 col-md-6">
                  <label className="form-label text-primary">
                    Authorization Level
                  </label>
                  <p className="form-label text-primary">
                    Level 1: Can create both conditional && final offer letters
                  </p>
                  <p className="form-label text-primary">
                    Level 2: Can create only conditional offer letters
                  </p>
                  <p className="form-label text-primary">
                    Level 3: Counsellors
                  </p>
                  <select
                    className="form-control"
                    value={authorizationLevel}
                    onChange={(e) => setAuthorizationLevel(e.target.value)}
                  >
                    <option value="">Select Level</option>
                    <option value={1}>Level 1</option>
                    <option value={2}>Level 2</option>
                    <option value={3}>Level 3</option>
                  </select>
                </div>
              )}
              <div className="mb-3 col-md-6">
                <label className="form-label text-primary">Password</label>
                <input
                  type="password"
                  placeholder="Password"
                  className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              {role === "ADMIN" && (
                <div className="mb-3 col-md-12">
                  <label className="form-label text-primary">
                    Assigned Universities
                  </label>
                  <CustomMultiSelect
                    options={universities}
                    selectedOptions={assignedUniversities}
                    onChange={handleUniversitiesChange}
                  />
                </div>
              )}
            </div>

            <button
              className="btn btn-primary"
              type="submit"
              onClick={handleUpdateUser}
              disabled={loading}
            >
              {loading ? (
                <ColorRing
                  visible={true}
                  height="22"
                  width="22"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{}}
                  wrapperClass="color-ring-wrapper"
                  colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                />
              ) : (
                "Update"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Settings;
