import React, { useState } from "react";
import styles from "./styles.module.css"; // Import the CSS module

const CustomMultiSelect = ({ options, selectedOptions, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      onChange(selectedOptions.filter((selected) => selected !== option));
    } else {
      onChange([...selectedOptions, option]);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={styles.customMultiSelect}>
      <div className={styles.selectBox} onClick={toggleDropdown}>
        <div className={styles.selectedValues}>
          {selectedOptions.length > 0
            ? selectedOptions.map((option) => (
                <span key={option._id} className={styles.selectedItem}>
                  {option.name}
                </span>
              ))
            : "Select universities"}
        </div>
        <div className={styles.arrow}>{isOpen ? "▲" : "▼"}</div>
      </div>
      {isOpen && (
        <div className={styles.dropdown}>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            className={styles.searchInput}
          />
          <div className={styles.options}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <div
                  key={option._id}
                  className={`${styles.option} ${
                    selectedOptions.includes(option) ? styles.selected : ""
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.name}
                </div>
              ))
            ) : (
              <div className={styles.noOptions}>No universities found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomMultiSelect;
