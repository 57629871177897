import React from "react";
import moment from "moment";
import { formatMoney } from "../../../utils/formatMoney";

const About = ({ program }) => {
  return (
    <div id="about" class="tab-pane fade active show">
      <div class="pt-4 profile-personal-info">
        <h5 class="text-primary mb-4">More Information</h5>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Name <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{program.name}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Level of Study <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{program.levelOfStudy}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Duration <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{program.duration}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Application Deadline <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>
              {moment(program.applicationDeadline).format("DD-MM-YYYY")}
            </span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Location <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{program.location}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Awarding Body <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{program.awardingBody}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Tuition Fee <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{formatMoney(program.tuitionFee)} XAF</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Initial Deposite <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{formatMoney(program.initialDeposit)} XAF</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Other Fees <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{program.otherFees}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Teaching Mode <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{program.teachingMode}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Teaching Methods <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{program.teachingMethods}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Medium of Instructions <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{program.mediumOfInstruction}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Academic Year <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{program.academicYear}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Start Date <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{moment(program.startDate).format("DD-MM-YYYY")}</span>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Description <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{program.description}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
