import React, { useState } from "react";
import Layout from "../../layouts/Layout";
import ComposeEmail from "../../components/email/composeEmail/ComposeEmail";
import ListEmail from "../../components/email/listEmail/ListEmail";
import ReadEmail from "../../components/email/readEmail/ReadEmail";
import { FaRegBell } from "react-icons/fa6";

const Emails = () => {
  const [selected, setSelected] = useState("");

  return (
    <Layout>
      <div className="row gx-0">
        <div className="col-lg-12">
          <div className="card rounded-0 mb-0 h-auto">
            <div className="card-body p-0 ">
              <div className="row gx-0">
                <div className="col-lg-3 col-xl-2 col-xxl-3">
                  <div
                    className="email-left-box dlab-scroll pt-3"
                    id="email-left"
                  >
                    <div className="p-0">
                      <a
                        href="javascript:void(0);"
                        className="btn text-white btn-block"
                        onClick={() => setSelected("COMPOSE")}
                      >
                        <i className="fa-solid fa-plus me-2"></i>Compose Email
                      </a>
                    </div>
                    <div className="mail-list rounded ">
                      <a
                        href="javascript:void(0);"
                        className="list-group-item active"
                        onClick={() => setSelected("LIST")}
                      >
                        <i className="fa-regular fa-envelope align-middle"></i>
                        Inbox{" "}
                        <span className="badge badge-purple badge-sm float-end rounded">
                          <FaRegBell
                            size={15}
                            style={{ padding: 0, margin: 0 }}
                          />
                        </span>
                      </a>
                      {/* <a
                        href="javascript:void(0);"
                        className={
                          selected === "List"
                            ? "list-group-item active"
                            : "list-group-item"
                        }
                        onClick={() => setSelected("SENT")}
                      >
                        <i className="fa-regular fa-paper-plane align-middle"></i>
                        Sent
                      </a> */}
                    </div>
                  </div>
                </div>
                {selected === "COMPOSE" ? (
                  <ComposeEmail />
                ) : selected === "LIST" ? (
                  <ListEmail setSelected={setSelected} />
                ) : selected === "READ" ? (
                  <ReadEmail />
                ) : (
                  <ListEmail setSelected={setSelected} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Emails;
