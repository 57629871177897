import React from "react";

const Contacts = ({ university }) => {
  return (
    <div id="contacts" class="tab-pane fade">
      <div class="pt-4 profile-personal-info">
        <h5 class="text-primary mb-4">Contact Information</h5>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Phone <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{university.contactInformation.phone}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Email <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{university.contactInformation.email}</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-sm-3 col-5">
            <h5 class="f-w-500">
              Website <span class="pull-end">:</span>
            </h5>
          </div>
          <div class="col-sm-9 col-7">
            <span>{university.websiteUrl}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
