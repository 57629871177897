// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_imageContainer__Huesr {
    display: flex;
    /* overflow: hidden; */
    width: 100%;
}

.styles_card__1\\+Ps7 {
    position: relative;
    width: 450px;
    /* height: 250px; */
    margin: 0 5px;
}

.styles_card__1\\+Ps7 .styles_image__LpdMP {
    height: 200px;
    margin-bottom: 10px;
}

.styles_card__1\\+Ps7 .styles_image__LpdMP img {
    width: 100%;
    height: 100%;
}

.styles_card__1\\+Ps7 .styles_details__GHw9e .styles_title__1EYgs {
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 15px;
}

.styles_card__1\\+Ps7 .styles_details__GHw9e p {
    font-size: 13px;
    margin: 0;
    color: #1D1D1D;
}

.styles_carousel__E8fQ6 .styles_btns__MJwM- {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.styles_button__lRaLa {
    background-color: transparent;
    border: 2px solid #2E0830;
    color: #2E0830;
    padding: 2px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

.styles_button__lRaLa:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/components/landing/testimonials/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,SAAS;IACT,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,6BAA6B;IAC7B,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB;IACrB,qBAAqB;IACrB,eAAe;IACf,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB","sourcesContent":[".imageContainer {\n    display: flex;\n    /* overflow: hidden; */\n    width: 100%;\n}\n\n.card {\n    position: relative;\n    width: 450px;\n    /* height: 250px; */\n    margin: 0 5px;\n}\n\n.card .image {\n    height: 200px;\n    margin-bottom: 10px;\n}\n\n.card .image img {\n    width: 100%;\n    height: 100%;\n}\n\n.card .details .title {\n    font-weight: bold;\n    margin-bottom: 8px;\n    font-size: 15px;\n}\n\n.card .details p {\n    font-size: 13px;\n    margin: 0;\n    color: #1D1D1D;\n}\n\n.carousel .btns {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 10px;\n}\n\n.button {\n    background-color: transparent;\n    border: 2px solid #2E0830;\n    color: #2E0830;\n    padding: 2px 5px;\n    text-align: center;\n    text-decoration: none;\n    display: inline-block;\n    font-size: 16px;\n    margin: 4px 2px;\n    cursor: pointer;\n}\n\n.button:disabled {\n    background-color: #cccccc;\n    cursor: not-allowed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageContainer": `styles_imageContainer__Huesr`,
	"card": `styles_card__1+Ps7`,
	"image": `styles_image__LpdMP`,
	"details": `styles_details__GHw9e`,
	"title": `styles_title__1EYgs`,
	"carousel": `styles_carousel__E8fQ6`,
	"btns": `styles_btns__MJwM-`,
	"button": `styles_button__lRaLa`
};
export default ___CSS_LOADER_EXPORT___;
