import React, { useState } from "react";
import Layout from "../../layouts/Layout";
import { addUser } from "../../redux/reducers/user";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { ColorRing } from "react-loader-spinner";
import uploadToS3 from "../../utils/awsS3Upload";

const AddStudent = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [amsId, setAmsId] = useState("");
  const [OLevelId, setOLevelId] = useState("");
  const [dob, setDob] = useState("");
  const [highschool, setHighSchool] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [gpa, setGpa] = useState("");
  const [nationalIDNumber, setNationalIDNumber] = useState("");
  const [citizenship, setCitizenship] = useState("");
  const [guardianName, setGuardianName] = useState("");
  const [guardianEmail, setGuardianEmail] = useState("");
  const [guardianPhone, setGuardianPhone] = useState("");
  const [guardianAge, setGuardianAge] = useState("");
  const [guardianAddress, setGuardianAddress] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Files
  const [profileImage, setProfileImage] = useState(null);
  const [preview, setPreview] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddStudent = async (e) => {
    try {
      setLoading(true);
      if (profileImage) {
        const res = await uploadToS3(
          profileImage,
          "profile-image",
          profileImage?.name
        );
        if (res) {
          const data = {
            role: "STUDENT",
            image: JSON.stringify(res),
            fullName: name,
            emailAddress: email,
            phoneNumber: phone,
            citizenship: citizenship,
            nationalIDNumber: nationalIDNumber,
            password,
            // student details
            studentDetails: {
              amsId: amsId,
              ordinaryLevelIdentificationNumber: OLevelId,
              dateOfBirth: dob,
              gender: gender,
              address: address,
              highSchoolName: highschool,
              gradesGPA: gpa,
              documents: [],
              // guardian details
              guardian: {
                guardianName: guardianName,
                guardianEmail: guardianEmail,
                guardianPhone: guardianPhone,
                guardianAge: guardianAge,
                guardianAddress: guardianAddress,
              },
            },
          };
          await dispatch(addUser(data)).then((res) => {
            if (res.meta.requestStatus === "rejected") {
              toast.error(res.payload);
              setLoading(false);
              return;
            } else {
              toast.success(res.payload.message);
              window.location.href = "/students";
              setLoading(false);
              return;
            }
          });
        } else {
          toast.error("an error occured");
          setLoading(false);
          return;
        }
      } else {
        const data = {
          role: "STUDENT",
          fullName: name,
          emailAddress: email,
          phoneNumber: phone,
          citizenship: citizenship,
          nationalIDNumber: nationalIDNumber,
          password,
          // student details
          studentDetails: {
            amsId: amsId,
            ordinaryLevelIdentificationNumber: OLevelId,
            dateOfBirth: dob,
            gender: gender,
            address: address,
            highSchoolName: highschool,
            gradesGPA: gpa,
            documents: [],
            // guardian details
            guardian: {
              guardianName: guardianName,
              guardianEmail: guardianEmail,
              guardianPhone: guardianPhone,
              guardianAge: guardianAge,
              guardianAddress: guardianAddress,
            },
          },
        };
        await dispatch(addUser(data)).then((res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            setLoading(false);
            return;
          } else {
            toast.success(res.payload.message);
            window.location.href = "/students";
            setLoading(false);
            return;
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Layout>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h5 className="mb-0">Student Details</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-xl-3 col-lg-4">
                  <label class="form-label text-primary">Profile Iimage</label>
                  <div class="avatar-upload">
                    <div class="avatar-preview">
                      {preview && (
                        <img
                          src={preview}
                          alt="Preview"
                          style={{ width: "100px", height: "100px" }}
                        />
                      )}
                    </div>
                    <div class="change-btn mt-2 mb-lg-0 mb-3">
                      <input
                        type="file"
                        class="form-control d-none"
                        id="logoUpload"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleFileChange}
                      />

                      <label
                        for="logoUpload"
                        class="dlab-upload mb-0 btn btn-primary btn-sm"
                      >
                        Choose File
                      </label>
                      {/* <button
                        class="btn btn-success light remove-img ms-2 btn-sm"
                        onClick={textUpload}
                      >
                        Save
                      </button> */}
                    </div>
                  </div>
                </div>

                <div className="col-xl-9 col-lg-8">
                  <div className="row">
                    <div className="col-xl-6 col-sm-6">
                      <div className="mb-3">
                        <label className="form-label text-primary">
                          Full Name<span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="James Wally"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label text-primary">
                          Email<span className="required">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="hello@example.com"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label text-primary">
                          Date of Birth
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={dob}
                          onChange={(e) => setDob(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label text-primary">
                          AMS ID
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={amsId}
                          onChange={(e) => setAmsId(e.target.value)}
                          placeholder="AMS ID"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label text-primary">
                          O/L ID<span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={OLevelId}
                          onChange={(e) => setOLevelId(e.target.value)}
                          placeholder="O/L ID"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label text-primary">
                          Citizenship
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={citizenship}
                          onChange={(e) => setCitizenship(e.target.value)}
                          placeholder="Citizenship"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <div className="mb-3">
                        <label className="form-label text-primary">
                          High School Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={highschool}
                          onChange={(e) => setHighSchool(e.target.value)}
                          placeholder="High School Name"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label text-primary">
                          Gender
                        </label>
                        <select
                          className="form-control"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option value="" disabled selected>
                            Select Gender
                          </option>
                          <option value="MALE">Male</option>
                          <option value="FEMALE">Female</option>
                          <option value="OTHER">Other</option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <label className="form-label text-primary">
                          Phone Number<span className="required">*</span>
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder="+123456789"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label text-primary">GPA</label>
                        <input
                          type="text"
                          className="form-control"
                          value={gpa}
                          onChange={(e) => setGpa(e.target.value)}
                          placeholder="GPA"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label text-primary">
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          placeholder="1234 Main St"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label text-primary">
                          Passport or NIC Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={nationalIDNumber}
                          onChange={(e) => setNationalIDNumber(e.target.value)}
                          placeholder="Passport or NIC Number"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label text-primary">
                          Password<span className="required">*</span>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="***********"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h5 className="mb-0">Guardian Details</h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-xl-6 col-sm-6">
                  <div className="mb-3">
                    <label className="form-label text-primary">
                      Guardian Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={guardianName}
                      onChange={(e) => setGuardianName(e.target.value)}
                      placeholder="Guardian Name"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label text-primary">
                      Guardian Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      value={guardianEmail}
                      onChange={(e) => setGuardianEmail(e.target.value)}
                      placeholder="guardian@example.com"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label text-primary">
                      Guardian Phone
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      value={guardianPhone}
                      onChange={(e) => setGuardianPhone(e.target.value)}
                      placeholder="+123456789"
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-sm-6">
                  <div className="mb-3">
                    <label className="form-label text-primary">
                      Guardian Age
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={guardianAge}
                      onChange={(e) => setGuardianAge(e.target.value)}
                      placeholder="Guardian Age"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label text-primary">
                      Guardian Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={guardianAddress}
                      onChange={(e) => setGuardianAddress(e.target.value)}
                      placeholder="1234 Main St"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <button
                  className="btn btn-primary"
                  onClick={handleAddStudent}
                  disabled={loading ? true : false}
                >
                  {loading ? (
                    <ColorRing
                      visible={true}
                      height="22"
                      width="22"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                    />
                  ) : (
                    "Save Changes"
                  )}
                </button>
                {/* <button className="btn btn-outline-primary ms-2">Cancel</button> */}
                <a className="btn btn-outline-primary ms-2" href="/students">
                  Cancel
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddStudent;
