import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./Filter.module.css";
import {
  MdDashboard,
  MdExpandMore,
  MdExpandLess,
  MdSettings,
  MdMenu,
  MdLocationOn,
  MdAssignment,
  MdAttachMoney,
  MdCalendarToday,
  MdAccessTime,
} from "react-icons/md";

const SidebarItem = ({ item, isExpanded, toggleDropdown, onChange }) => {
  return (
    <div>
      <div
        className={styles.sidebarItem}
        onClick={() => toggleDropdown(item.id)}
      >
        <div className={styles.sidebarIcon}>{item.icon}</div>
        <div
          className={`${styles.sidebarText} ${isExpanded ? "" : styles.hidden}`}
        >
          {item.title}
        </div>
        <div className={styles.sidebarIcon}>
          {item.subItems.length > 0 &&
            (item.isOpen ? <MdExpandLess /> : <MdExpandMore />)}
        </div>
      </div>
      {item.subItems.length > 0 && (
        <div
          className={`${styles.sidebarDropdown} ${
            item.isOpen ? styles.show : ""
          }`}
        >
          {item.subItems.map((subItem) => (
            <div key={subItem.id} className={styles.subItem}>
              <input
                type="checkbox"
                id={`checkbox-${subItem.id}`}
                checked={subItem.isChecked}
                onChange={() => onChange({ item, subItem })}
              />
              <label htmlFor={`checkbox-${subItem.id}`}>{subItem.title}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

SidebarItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    icon: PropTypes.element,
    subItems: PropTypes.array,
    isOpen: PropTypes.bool,
  }).isRequired,
  isExpanded: PropTypes.bool.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
};

const Sidebar = ({
  citiesArray,
  programLevels,
  tuitionFees,
  intakes,
  durations,
  // Initial Values
  location,
  levelOfStudy,
  academicYear,
  tuitionFee,
  duration,
  // set states
  setLocation,
  setLevelOfStudy,
  setAcademicYear,
  setTuitionFee,
  setDuration,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [sidebarItems, setSidebarItems] = useState([]);

  useEffect(() => {
    if (citiesArray.length > 0) {
      const generatedItems = [
        {
          id: 1,
          title: "Location",
          icon: <MdLocationOn />,
          subItems: citiesArray.map((item, index) => ({
            id: index + 1,
            title: item,
            icon: <MdLocationOn />,
            subItems: [],
            isOpen: false,
          })),
          isOpen: false,
        },
        {
          id: 2,
          title: "Program Level",
          icon: <MdAssignment />,
          subItems: programLevels.map((item, index) => ({
            id: index + 1,
            title: item,
            icon: <MdAssignment />,
            subItems: [],
            isOpen: false,
          })),
          isOpen: false,
        },
        {
          id: 4,
          title: "Tuition Fee (XAF)",
          icon: <MdAttachMoney />,
          subItems: tuitionFees.map((item, index) => ({
            id: 4 + index + item,
            title: item,
            icon: <MdAttachMoney />,
            subItems: [],
            isOpen: false,
          })),
          isOpen: false,
        },
        {
          id: 5,
          title: "Intakes",
          icon: <MdSettings />,
          subItems: intakes.map((item, index) => ({
            id: 5 + index + item,
            title: item,
            icon: <MdSettings />,
            subItems: [],
            isOpen: false,
          })),
          isOpen: false,
        },
        {
          id: 6,
          title: "Duration",
          icon: <MdAccessTime />,
          subItems: durations.map((item, index) => ({
            id: 6 + index + item,
            title: item,
            icon: <MdAccessTime />,
            subItems: [],
            isOpen: false,
          })),
          isOpen: false,
        },
      ];

      setSidebarItems(generatedItems);
    }
  }, [citiesArray, programLevels, tuitionFees, intakes, durations]);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleDropdown = (id) => {
    const toggleItems = (items) => {
      return items.map((item) => {
        if (item.id === id) {
          item.isOpen = !item.isOpen;
        }
        if (item.subItems.length > 0) {
          item.subItems = toggleItems(item.subItems);
        }
        return item;
      });
    };

    setSidebarItems(toggleItems(sidebarItems));
  };

  // const handleCheckboxChange = (data) => {
  //   const { title: itemTitle } = data.item;
  //   const { title: subItemTitle } = data.subItem;

  //   const toggleOption = (options, setOptions, option) => {
  //     setOptions(
  //       options.includes(option)
  //         ? options.filter((item) => item !== option)
  //         : [...options, option]
  //     );
  //   };

  //   if (itemTitle === "Location") {
  //     toggleOption(location, setLocation, subItemTitle);
  //   }

  //   if (itemTitle === "Program Level") {
  //     toggleOption(levelOfStudy, setLevelOfStudy, subItemTitle);
  //   }

  //   if (itemTitle === "Intakes") {
  //     toggleOption(academicYear, setAcademicYear, subItemTitle);
  //   }

  //   if (itemTitle === "Tuition Fee (XAF)") {
  //     const [start, end] = subItemTitle.split(" - ");
  //     const extractNumber = (str) => parseInt(str.replace(/[^0-9]/g, ""), 10);
  //     const option = [extractNumber(start), extractNumber(end)];
  //     toggleOption(tuitionFee, setTuitionFee, option);
  //   }

  //   if (itemTitle === "Duration") {
  //     const parseDuration = (str) => {
  //       if (str.includes("or less")) {
  //         const years = parseInt(str, 10);
  //         return { min: 0, max: years };
  //       }
  //       if (str.includes("and More")) {
  //         const years = parseInt(str, 10);
  //         return { min: years, max: Infinity };
  //       }
  //       if (str.includes("-")) {
  //         const [min, max] = str.split("-").map((s) => parseInt(s.trim(), 10));
  //         return { min, max };
  //       }
  //       const years = parseInt(str, 10);
  //       return { min: years, max: years };
  //     };

  //     const { min, max } = parseDuration(subItemTitle);
  //     toggleOption(duration, setDuration, [min, max]);
  //   }
  // };

  const handleCheckboxChange = (data) => {
    if (data.item.title === "Location") {
      const option = data.subItem.title;
      console.log(location, levelOfStudy);
      console.log(typeof location);
      if (location.includes(option)) {
        setLocation(location.filter((item) => item !== option));
      } else {
        setLocation([...location, option]);
      }
    }

    if (data.item.title === "Program Level") {
      const option = data.subItem.title;
      if (levelOfStudy.includes(option)) {
        setLevelOfStudy(levelOfStudy.filter((item) => item !== option));
      } else {
        setLevelOfStudy([...levelOfStudy, option]);
      }
    }

    if (data.item.title === "Intakes") {
      const option = data.subItem.title;
      if (academicYear.includes(option)) {
        setAcademicYear(academicYear.filter((item) => item !== option));
      } else {
        setAcademicYear([...academicYear, option]);
      }
    }

    if (data.item.title === "Tuition Fee (XAF)") {
      const range = data.subItem.title;
      const [start, end] = range.split(" - ");
      const extractNumber = (str) => {
        return parseInt(str.replace(/[^0-9]/g, ""), 10);
      };

      const startValue = extractNumber(start);
      const endValue = extractNumber(end);

      const option = [startValue, endValue];
      if (tuitionFee.includes(option)) {
        setTuitionFee(tuitionFee.filter((item) => item !== option));
      } else {
        setTuitionFee([...tuitionFee, option]);
      }
    }

    if (data.item.title === "Duration") {
      const str = data.subItem.title;
      const parseDuration = () => {
        if (str.includes("or less")) {
          const years = parseInt(str, 10);
          return { min: 0, max: years };
        }

        if (str.includes("and More")) {
          const years = parseInt(str, 10);
          return { min: years, max: Infinity };
        }

        if (str.includes("-")) {
          const [min, max] = str.split("-").map((s) => parseInt(s.trim(), 10));
          return { min, max };
        }

        const years = parseInt(str, 10);
        return { min: years, max: years };
      };
      const { min, max } = parseDuration();

      const option = [min, max];
      if (duration.includes(option)) {
        setDuration(duration.filter((item) => item !== option));
      } else {
        setDuration([...duration, option]);
      }
    }
  };

  return (
    <div className={`${styles.sidebar} ${isExpanded ? "" : styles.collapsed}`}>
      {sidebarItems.map((item) => (
        <SidebarItem
          key={item.id}
          item={item}
          isExpanded={isExpanded}
          toggleDropdown={toggleDropdown}
          onChange={handleCheckboxChange}
        />
      ))}
    </div>
  );
};

Sidebar.propTypes = {
  citiesArray: PropTypes.array.isRequired,
  programLevels: PropTypes.array.isRequired,
  tuitionFees: PropTypes.array.isRequired,
  intakes: PropTypes.array.isRequired,
  durations: PropTypes.array.isRequired,
  // Initial Values
  location: PropTypes.array.isRequired,
  levelOfStudy: PropTypes.array.isRequired,
  academicYear: PropTypes.array.isRequired,
  tuitionFee: PropTypes.array.isRequired,
  duration: PropTypes.array.isRequired,
};

export default Sidebar;
